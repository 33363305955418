import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export function MaxMessagingRoundsSpinner() {
  const { control } = useFormContext();

  return (
    <Controller
      name="maxMessagingRounds"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack>
          <Typography display="flex" alignContent="center" alignItems="center" component="div">
            Do not send more than
            <Box mx={1}>
              <TextField
                type="number"
                value={value}
                onChange={onChange}
                size="small"
                inputProps={{ sx: { width: '60px' }, min: 1, 'data-testid': 'maxMessagingRounds' }}
              />{' '}
            </Box>
            message(s) to the same patient
          </Typography>
        </Stack>
      )}
    />
  );
}
