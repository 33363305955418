import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { X_TRACE_ID } from '../util/constants';

export function traceIdInterceptor(config: InternalAxiosRequestConfig) {
  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }

  config.headers[X_TRACE_ID] =
    config.headers[X_TRACE_ID] ?? crypto.randomUUID();

  return config;
}