import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  children: ReactNode;
};

export default function PrimarySidePanel({ title, children }: Props) {
  return (
    <>
      <Box
        m={2}
        mb={6}
        sx={(theme) => ({ borderColor: theme.palette.primary.main, borderWidth: '1px', borderStyle: 'solid' })}
      >
        <Box
          p={1}
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
          })}
        >
          <Typography variant="h6" component="div" sx={(theme) => ({ color: theme.palette.primary.contrastText })}>
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </>
  );
}
