import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from './reducers/rootReducer';

const rootReducer = createRootReducer();

export const configuredStore = () => {
  const store = configureStore({
    reducer: rootReducer,
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept(
      './reducers/rootReducer',
      // eslint-disable-next-line global-require
      () => store.replaceReducer(require('./reducers/rootReducer').default)
    );
  }
  return store;
};

export const store = configuredStore();

export type RootState = ReturnType<typeof rootReducer>;

export type Store = ReturnType<typeof configuredStore>;

export type AppDispatch = typeof store.dispatch;
