import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import PasswordField from '../../../shared/PasswordField/PasswordField';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import TextField from '../../../shared/TextField/TextField';
import Button from '@mui/material/Button';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { checkPartnerApiCredentials } from '../../../../services/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface Props {
  username: string | undefined;
  password: string | undefined;
  updateCredentials: (username: string, password: string) => Promise<void>;
}

export function PartnerApiCredentials({ username, password, updateCredentials }: Props) {
  const [updatingCredentials, setUpdatingCredentials] = useState(false);
  const [testingCredentials, setTestingCredentials] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm({
    defaultValues: {
      username,
      password,
    } as FieldValues,
  });

  const { organisation } = useCurrentOrganisationDetails();

  const dispatch = useDispatch();

  async function onUpdateCredentials({ username: newUsername, password: newPassword }: FieldValues) {
    setUpdatingCredentials(true);
    try {
      await updateCredentials(newUsername, newPassword);
      reset({
        username: newUsername,
        password: newPassword,
      });
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to save credentials', error));
    } finally {
      setUpdatingCredentials(false);
    }
  }

  const onTestCredentials = async ({ username: newUsername, password: newPassword }: FieldValues) => {
    try {
      setTestingCredentials(true);
      await checkPartnerApiCredentials(organisation!.organisation, newUsername, newPassword);
      dispatch(enqueueSuccessNotification('Connection successful'));
    } catch (error) {
      dispatch(enqueueErrorNotification('Connection failed', error));
    } finally {
      setTestingCredentials(false);
    }
  };

  return (
    <Stack maxWidth={350} gap={1}>
      <Typography variant="h6" gutterBottom>
        Partner API credentials
      </Typography>

      <form>
        <Stack gap={2}>
          <TextField
            id="username"
            name="username"
            control={control}
            error={errors.username as FieldError}
            label="Username"
            required
            rules={{ required: 'Username is required' }}
          />

          <PasswordField
            id="password"
            name="password"
            control={control}
            error={errors.password as FieldError}
            label="Password"
            required
            rules={{ required: 'Password is required' }}
          />

          <Stack direction="row" gap={1} width="100%">
            <LoadingButton
              data-testid="test-credentials"
              variant="outlined"
              onClick={handleSubmit(onTestCredentials)}
              fullWidth
              loading={testingCredentials}
              disabled={testingCredentials || updatingCredentials}
            >
              Test
            </LoadingButton>

            <Button
              data-testid="reset-credentials"
              variant="outlined"
              onClick={() => reset()}
              fullWidth
              disabled={!isDirty}
            >
              Reset
            </Button>

            <LoadingButton
              data-testid="update-credentials"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onUpdateCredentials)}
              fullWidth
              loading={updatingCredentials}
              disabled={testingCredentials || updatingCredentials || !isDirty}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
