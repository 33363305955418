import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import Stack from '@mui/material/Stack';
import { Controller, useFormContext } from 'react-hook-form';

export function Schedule() {
  const { control } = useFormContext();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography>Run every</Typography>

      <Controller
        name="scheduleDaysOfWeek"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <ToggleButtonGroup
              color="primary"
              value={value}
              size="small"
              onChange={(event, newValue) => {
                if (newValue.length === 0) {
                  event.preventDefault();
                } else {
                  onChange(newValue);
                }
              }}
              aria-label="Day of Week"
            >
              <ToggleButton value="MON">Mon</ToggleButton>
              <ToggleButton value="TUE">Tue</ToggleButton>
              <ToggleButton value="WED">Wed</ToggleButton>
              <ToggleButton value="THU">Thu</ToggleButton>
              <ToggleButton value="FRI">Fri</ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />

      <Typography>at</Typography>

      <Controller
        name="scheduleHour"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select size="small" value={value} onChange={onChange} aria-label="Hour">
            {[...Array(17 - 8 + 1).keys()]
              .map((n) => n + 8)
              .map((n) => (
                <MenuItem key={n} value={n}>
                  {`${n}`.padStart(2, '0')}:00
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </Stack>
  );
}
