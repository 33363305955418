import styled from '@mui/system/styled';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  border: '1px solid',
  borderColor: theme.palette.grey['300'],

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

export const Accordion = styled(MuiAccordion)({
  '&:before': {
    display: 'none',
  },
});

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey['300'],
  borderTop: 0,
}));
