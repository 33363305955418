import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface Props {
  error: string;
}

export function FailedExecutionDetails({ error }: Props) {
  return (
    <Box m={1} maxWidth="50%">
      <Alert severity="error" variant="outlined" sx={{ border: 0 }}>
        <AlertTitle>Execution failed with an error</AlertTitle>
        {error}
      </Alert>
    </Box>
  );
}
