import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HTTP_TIMEOUT } from './constants';
import { handleApiError } from './errors/handleApiError';

const axiosInstances: { [propKey: string]: AxiosInstance } = {};

async function getIdToken(): Promise<string> {
  const session = await fetchAuthSession();
  return session.tokens?.idToken?.toString()!;
}

export async function callApi<Return, Body>(
  method: (url: string, body?: any, config?: any) => AxiosPromise<Return>,
  url: string,
  body?: Body,
  config?: AxiosRequestConfig<Body>,
  nullOnError: boolean = false,
  publicApi: boolean = false,
): Promise<Return | null> {
  try {
    if (publicApi === false) {
      const idToken = await getIdToken();
      if (config === undefined) {
        config = {};
      }
      config.headers = { Authorization: idToken };
    }

    let response: AxiosResponse<Return>;
    if (body !== undefined) {
      response = await method(url, body, config);
    } else if (config !== undefined) {
      response = await method(url, config);
    } else {
      response = await method(url);
    }
    return response.data;
  } catch (error) {
    if (nullOnError === true && axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    handleApiError(error);
  }
}

export function getApiClient(apiName: string): AxiosInstance {
  if (axiosInstances[apiName] !== undefined && axiosInstances[apiName] !== null) {
    return axiosInstances[apiName];
  }

  const baseUrl = Amplify.getConfig().API?.REST?.[apiName].endpoint!;

  axiosInstances[apiName] = axios.create({
    baseURL: baseUrl,
    timeout: HTTP_TIMEOUT,
  });

  return axiosInstances[apiName];
}

export function resetApiClient(apiName: string): void {
  if (apiName in axiosInstances) {
    delete axiosInstances[apiName];
  }
}
