import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

interface Props {
  customMessage?: string;
  sx?: SxProps;
}

export function NoDataToDisplay({ customMessage = 'No data to display', sx = null }: Props) {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle2">{customMessage}</Typography>
    </Box>
  );
}
