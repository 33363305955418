import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import React, { ReactNode } from 'react';
import { RefreshButton } from './RefreshButton/RefreshButton';
import { RunButton } from './RunButton/RunButton';

export const TRIGGER_RULE_DISABLED = 'disabled';
export const TRIGGER_RULE_HIDDEN = 'hidden';

interface Props {
  triggerRule: (() => Promise<unknown>) | typeof TRIGGER_RULE_DISABLED | typeof TRIGGER_RULE_HIDDEN;
  refresh: () => Promise<unknown>;
}

export function Toolbar({ triggerRule, refresh }: Props): ReactNode {
  return (
    <GridToolbarContainer>
      {triggerRule === TRIGGER_RULE_HIDDEN ? (
        <></>
      ) : triggerRule === TRIGGER_RULE_DISABLED ? (
        <RunButton onClick={async () => {}} disabled={true} />
      ) : (
        <RunButton onClick={triggerRule} />
      )}
      <RefreshButton refresh={refresh} />
    </GridToolbarContainer>
  );
}
