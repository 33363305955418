import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { OrganisationLevelConfig } from './OrganisationLevelConfig/OrganisationLevelConfig';
import { RulesSection } from './RulesSection/RulesSection';
import { RulesContextProvider } from '../../../context/RulesContext/RulesContext';
import { useScheduledMessagingConfig } from '../../../hooks/useScheduledMessagingConfig/useScheduledMessagingConfig';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';

export function ScheduledMessagingConfig() {
  const { config, loading, updateOrganisationConfig } = useScheduledMessagingConfig();
  const [updatingConfig, setUpdatingConfig] = useState(false);

  const dispatch = useDispatch();

  async function onAvailableChange(available: boolean) {
    setUpdatingConfig(true);
    try {
      await updateOrganisationConfig({ available });
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to update the configuration', error));
    } finally {
      setUpdatingConfig(false);
    }
  }

  async function onReportsRecipientUserIdChange(userId: string | undefined) {
    await updateOrganisationConfig({ reportsRecipientUserId: userId ?? null });
  }

  async function onUpdatePartnerApiCredentials(username: string, password: string) {
    await updateOrganisationConfig({ partnerApiCredentials: { username, password } });
  }

  return (
    <LoadingSection loading={loading}>
      <Stack pl={2} gap={2}>
        <Backdrop open={updatingConfig} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress data-testid="sched-messaging-spinner" />
        </Backdrop>
        <OrganisationLevelConfig
          config={config}
          onAvailableChange={onAvailableChange}
          onReportsRecipientUserIdChange={onReportsRecipientUserIdChange}
          onUpdatePartnerApiCredentials={onUpdatePartnerApiCredentials}
        />

        {config?.available && (
          <RulesContextProvider>
            <RulesSection />
          </RulesContextProvider>
        )}
      </Stack>
    </LoadingSection>
  );
}
