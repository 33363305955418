import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface Palette {
    nhsPrimary: Palette['primary'];
  }
  interface PaletteOptions {
    nhsPrimary?: PaletteOptions['primary'];
  }

  interface Theme {
    customSizes: {
      navbarHeightInPx: number;
    };
  }
  interface ThemeOptions {
    customSizes?: {
      navbarHeightInPx?: number;
    };
  }
}

export const currentTheme = createTheme({
  palette: {
    primary: {
      main: '#990101',
    },
    nhsPrimary: {
      main: '#005EB8',
    },
  },
  customSizes: {
    navbarHeightInPx: 64,
  },
});
