import React from 'react';
import {
  FAILURE,
  RuleExecutionStatus,
  RuleExecutionSummaryReport,
  SUCCESS,
} from '../../../../../domain/ScheduledMessaging';
import { SuccessfulExecutionDetails } from './SuccessfulExecutionDetails';
import { FailedExecutionDetails } from './FailedExecutionDetails';
import { RunningExecutionDetails } from './RunningExecutionDetails';

interface Props {
  id: string;
  status: RuleExecutionStatus;
  summary?: RuleExecutionSummaryReport;
  error?: string;
}

export function ExecutionDetails({ id, status, summary, error }: Props) {
  return status === SUCCESS ? (
    <SuccessfulExecutionDetails id={id} summary={summary!} />
  ) : status === FAILURE ? (
    <FailedExecutionDetails error={error!} />
  ) : (
    <RunningExecutionDetails />
  );
}
