import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { AppointmentBookingCheckbox } from '../AppointmentBookingCheckbox/AppointmentBookingCheckbox';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { useAppointmentBookingConfig } from '../../../../hooks/useAppointmentBookingConfig/useAppointmentBookingConfig';
import { MultipleChoiceSelect } from '../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';
import TextField from '../../../shared/TextField/TextField';
import { useAppointmentSlotsConfig } from '../../../../hooks/useAppointmentSlotsConfig/useAppointmentSlotsConfig';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

export function AppointmentBooking() {
  const form = useFormContext();

  const errors = form.formState.errors;

  const addInvitationLink = form.watch('appointmentsAddInvitationLink');

  const { config } = useAppointmentBookingConfig();
  const { enabled } = config ?? { enabled: false };

  const {
    loading: appointmentSlotsConfigLoading,
    config: appointmentSlotsConfig,
    error: appointmentSlotsError,
  } = useAppointmentSlotsConfig(!!addInvitationLink);

  const sortedSlotTypes = appointmentSlotsConfig?.slotTypes.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      <AppointmentBookingCheckbox disabled={!enabled} opening={appointmentSlotsConfigLoading} />

      <Collapse in={addInvitationLink && !appointmentSlotsConfigLoading} timeout="auto" unmountOnExit={true}>
        {appointmentSlotsError ? (
          <Box marginLeft="39px" marginBottom={2}>
            <Alert severity="info">
              <Typography marginBottom={0.75}>
                Self-book is currently not available for your organisation — contact{' '}
                <Link underline="always" target="_blank" href="mailto:support@abtrace.co">
                  support@abtrace.co
                </Link>{' '}
                for more information.
              </Typography>
              <Typography>
                {' '}
                Patients clicking this link will be taken to a web form where they can detail their availability. This
                will then be emailed to your practice.
              </Typography>
            </Alert>
          </Box>
        ) : (
          <Stack gap={1.5} marginLeft="39px" marginBottom={2}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                Allow patients to self-book an appointment within
              </FormLabel>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <TextField
                    name="appointmentsBookableWithinDays"
                    label="Days"
                    type="number"
                    control={form.control}
                    error={errors.appointmentsBookableWithinDays as FieldError}
                    rules={{
                      required: 'Days are required',
                    }}
                    size="small"
                    inputProps={{ sx: { width: '60px' }, min: 1 }}
                  />
                }
                label={
                  <Typography
                    component="span"
                    sx={{
                      marginLeft: 1,
                    }}
                  >
                    days after receiving the invitation
                  </Typography>
                }
              />
            </FormControl>

            <Controller
              name="appointmentsSlotTypes"
              control={form.control}
              render={({ field: { onChange: controlledOnChange, value } }) => (
                <FormControl sx={{ flex: 'auto' }}>
                  <MultipleChoiceSelect
                    noOptionsText="No slots available"
                    options={sortedSlotTypes ?? []}
                    value={sortedSlotTypes?.filter((slotType) => value.includes(slotType.id.toString())) ?? []}
                    onChange={(newValue) => {
                      controlledOnChange(newValue.map((slotType) => slotType.id.toString()));
                    }}
                    getPrimaryText={(slotType) => slotType.name}
                    label="Slot types"
                    placeholder="Slot types"
                    loading={appointmentSlotsConfigLoading}
                    getInputParams={(params) => ({
                      ...params,
                      error: !!(errors.appointmentsSlotTypes as FieldError)?.type,
                      helperText: (errors.appointmentsSlotTypes as FieldError)?.message,
                    })}
                    slotProps={{
                      paper: {
                        sx: {
                          border: '1px solid',
                          boxShadow: 1,
                        },
                      },
                    }}
                  />
                </FormControl>
              )}
              rules={{
                required: 'Slot types are required',
              }}
              defaultValue={[]}
            />

            <TextField
              name={'appointmentsDescription'}
              control={form.control}
              error={errors.appointmentsDescription as FieldError}
              rules={{
                required: 'Description is required',
              }}
              label="Description"
              required
              variant="outlined"
              size="small"
              fullWidth
              helperText={(errors.appointmentsDescription as FieldError)?.message ?? 'e.g., "Routine tests"'}
            />
          </Stack>
        )}
      </Collapse>
    </Box>
  );
}
