import React, { useState } from 'react';
import { MessageRecordingChoice, Rule, RuleOrganisationConfig } from '../../domain/ScheduledMessaging';
import { useRules } from '../../hooks/useRules/useRules';
import { useScheduledMessagingConfig } from '../../hooks/useScheduledMessagingConfig/useScheduledMessagingConfig';
import { SCHEDULED_MESSAGING_DEFAULT_SCHEDULE } from '../../util/constants';

export interface RulesContext {
  rules: Record<string, Rule> | undefined;
  configuredRules: Record<string, RuleOrganisationConfig> | undefined;
  loading: boolean;
  error: unknown;
  selectedRule: { id: string; definition: Rule; config: RuleOrganisationConfig } | null;
  getRequiredSelectedRule(): { id: string; definition: Rule; config: RuleOrganisationConfig };
  swapRule(ruleId: string): void;
  addRuleConfig(ruleId: string): Promise<void>;
  updateRuleConfig(ruleId: string, newRuleConfig: RuleOrganisationConfig): Promise<void>;
  removeRuleConfig(ruleId: string): Promise<void>;
  removeRule: (ruleId: string) => Promise<void>;
  hasUnsavedChanges: boolean;
  toggleUnsavedChanges(dirty: boolean): void;
}

const rulesContext = React.createContext<RulesContext>({
  rules: undefined,
  configuredRules: undefined,
  loading: false,
  error: null,
  selectedRule: null,
  getRequiredSelectedRule: () => {
    throw new Error('Invalid state: no rule selected');
  },
  swapRule: () => {},
  addRuleConfig: async () => {
    throw new Error('Not implemented');
  },
  updateRuleConfig: async () => {
    throw new Error('Not implemented');
  },
  removeRuleConfig: async () => {
    throw new Error('Not implemented');
  },
  removeRule: async () => {
    throw new Error('Not implemented');
  },
  hasUnsavedChanges: false,
  toggleUnsavedChanges: () => {},
});

interface Props {
  initiallySelectedRuleId?: string;
  children: React.ReactNode;
}

export function RulesContextProvider({ initiallySelectedRuleId, children }: Props) {
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(initiallySelectedRuleId ?? null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { rules, loading: rulesLoading, error: rulesError, removeRule: onRemoveRole } = useRules();

  const {
    config,
    loading: configLoading,
    error: configError,
    updateRuleConfig: onUpdateRuleConfig,
    removeRuleConfig: onRemoveRuleConfig,
  } = useScheduledMessagingConfig();

  function swapRule(newRuleId: string) {
    if (newRuleId === selectedRuleId) {
      return;
    }

    if (hasUnsavedChanges) {
      throw new Error('Unable to select another rule while the current one has unsaved changes');
    }

    if (config?.rules?.[newRuleId]) {
      setSelectedRuleId(newRuleId);
      setHasUnsavedChanges(false);
    } else {
      throw new Error(`Rule ${newRuleId} doesn't exist`);
    }
  }

  async function updateRuleConfig(ruleId: string, newRuleConfig: RuleOrganisationConfig) {
    if (config?.rules?.[ruleId]) {
      await onUpdateRuleConfig(ruleId, newRuleConfig);
      setHasUnsavedChanges(false);
    } else {
      throw new Error(`Rule ${ruleId} doesn't exist`);
    }
  }

  async function addRuleConfig(ruleId: string) {
    if (rules?.[ruleId]) {
      await onUpdateRuleConfig(ruleId, {
        enabled: false,
        patientSelectionCriteria: {
          maxDefinedByPractice: 20,
          maxAllowedByAbtrace: 500,
          minDaysBetweenMessagingRounds: 7,
          maxMessagingRounds: 3,
        },
        appointments: {
          addInvitationLink: false,
        },
        extraCodes: [],
        messageRecordingChoice: MessageRecordingChoice.FreeText,
        schedule: SCHEDULED_MESSAGING_DEFAULT_SCHEDULE,
      });
    } else {
      throw new Error(`Rule ${ruleId} doesn't exist`);
    }
  }

  async function removeRuleConfig(ruleId: string) {
    if (config?.rules?.[ruleId]) {
      await onRemoveRuleConfig(ruleId);
      setSelectedRuleId(null);
      setHasUnsavedChanges(false);
    } else {
      throw new Error(`Rule ${ruleId} doesn't exist`);
    }
  }

  async function removeRule(ruleId: string) {
    if (config?.rules?.[ruleId]) {
      await onRemoveRole(ruleId);
      setSelectedRuleId(null);
      setHasUnsavedChanges(false);
    } else {
      throw new Error(`Rule ${ruleId} doesn't exist`);
    }
  }

  const selectedRule =
    selectedRuleId && config?.rules?.[selectedRuleId] && rules?.[selectedRuleId]
      ? {
          id: selectedRuleId,
          definition: rules[selectedRuleId],
          config: config.rules[selectedRuleId],
        }
      : null;

  const value: RulesContext = {
    rules,
    configuredRules: config?.rules,
    loading: rulesLoading || configLoading,
    error: rulesError || configError,
    selectedRule,
    getRequiredSelectedRule() {
      if (!selectedRule) {
        throw new Error('Invalid state: no rule selected');
      }
      return selectedRule;
    },
    swapRule,
    removeRule,
    addRuleConfig,
    updateRuleConfig,
    removeRuleConfig,
    hasUnsavedChanges,
    toggleUnsavedChanges: (dirty: boolean) => {
      if (selectedRule) {
        setHasUnsavedChanges(dirty);
      }
    },
  };

  return <rulesContext.Provider value={value}>{children}</rulesContext.Provider>;
}

export default rulesContext;
