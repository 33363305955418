import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import rulesContext from '../../context/RulesContext/RulesContext';
import {
  SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY,
  SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_STALE_TIME,
} from '../../util/queries';
import { RuleExecutionReport } from '../../domain/ScheduledMessaging';
import { loadRuleExecutionHistory, triggerRule } from '../../services/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseRuleExecutionHistoryResult {
  history?: RuleExecutionReport[];
  nextScheduledRun?: string;
  loading: boolean;
  error: unknown;
  refresh: () => Promise<unknown>;
  triggerRule: () => Promise<unknown>;
}

export function useRuleExecutionHistory(): UseRuleExecutionHistoryResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const { selectedRule } = useContext(rulesContext);

  const { data, isPending, isLoading, error, refetch } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY, organisation, selectedRule?.id],
    queryFn: () => loadRuleExecutionHistory(organisation, selectedRule!.id),
    staleTime: SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_STALE_TIME,
    retry: false,
    enabled: !!selectedRule?.id,
  });

  async function triggerRuleExecution(): Promise<unknown> {
    if (!selectedRule) {
      throw new Error('No rule selected');
    }

    return triggerRule(organisation, selectedRule.id);
  }

  return {
    history: data?.executions,
    nextScheduledRun: data?.nextScheduledRun,
    loading: isPending || isLoading,
    error,
    refresh: refetch,
    triggerRule: triggerRuleExecution,
  };
}
