import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function PatientNeedsSummarySwitch() {
  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      name="includePatientNeedsSummary"
      render={({ field }) => (
        <FormControlLabel
          label="Include patient needs summary"
          control={<Checkbox checked={field.value} onChange={(_, value) => field.onChange(value)} size="small" />}
        />
      )}
    />
  );
}
