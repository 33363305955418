import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export function RunningExecutionDetails() {
  return (
    <Box m={1} maxWidth="50%">
      <Alert severity="info" variant="outlined" sx={{ border: 0 }}>
        <AlertTitle>The rule is executing...</AlertTitle>
        Please come back later to see the execution details.
      </Alert>
    </Box>
  );
}
