import { useGridRootProps } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { ButtonProps } from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import {
  enqueueErrorNotification,
  enqueueInfoNotification,
} from '../../../../../../redux/reducers/notificationsReducer';

type Props = {
  onClick: () => Promise<unknown>;
} & ButtonProps;

export function RunButton({ onClick, ...props }: Props) {
  const [starting, setStarting] = useState(false);

  const rootProps = useGridRootProps();

  const dispatch = useDispatch();

  return (
    <LoadingButton
      size="small"
      startIcon={<PlayArrowIcon />}
      {...props}
      onClick={async () => {
        setStarting(true);
        try {
          await onClick();
          dispatch(enqueueInfoNotification('The rule has started. Please refresh the list in a few seconds'));
        } catch (error) {
          dispatch(enqueueErrorNotification('Unable to start the rule', error));
        } finally {
          setStarting(false);
        }
      }}
      {...rootProps.slotProps?.baseButton}
      loading={starting}
    >
      Run Now
    </LoadingButton>
  );
}
