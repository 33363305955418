import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { MessageRecordingChoice } from '../../../../domain/ScheduledMessaging';

export function MessageRecordingOptions() {
  const { control } = useFormContext();

  return (
    <Box display="flex">
      <Controller
        name="messageRecordingChoice"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <FormControl
                component={Box}
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <FormLabel id="message-recording-choice-label">Record message content in EHR as</FormLabel>
                <RadioGroup
                  aria-labelledby="message-recording-choice-label"
                  name="message-recording-choice-radio-group"
                  row={true}
                  value={value}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value={MessageRecordingChoice.FreeText}
                    control={<Radio size="small" />}
                    label="Free text note"
                  />
                  <FormControlLabel
                    value={MessageRecordingChoice.Attachment}
                    control={<Radio size="small" />}
                    label="Attachment"
                  />
                </RadioGroup>
              </FormControl>
            </>
          );
        }}
        defaultValue={MessageRecordingChoice.FreeText}
      />
    </Box>
  );
}
