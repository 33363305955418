import { useGridRootProps } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { ButtonProps } from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props extends ButtonProps {
  refresh: () => Promise<unknown>;
}

export function RefreshButton({ refresh, ...props }: Props) {
  const [refreshing, setRefreshing] = useState(false);

  const rootProps = useGridRootProps();

  async function onClick() {
    setRefreshing(true);

    try {
      await refresh();
    } finally {
      setRefreshing(false);
    }
  }

  return (
    <LoadingButton
      size="small"
      startIcon={<RefreshIcon />}
      {...props}
      onClick={onClick}
      {...rootProps.slotProps?.baseButton}
      loading={refreshing}
    >
      Refresh
    </LoadingButton>
  );
}
