import { useRef, useState } from 'react';

export interface UseDialogConnector<T, V = void> {
  config: T | null;
  close: () => void;
  submit: (value: V) => Promise<unknown>;
}

export interface UseDialogResult<T, V> {
  open: (config: T) => { then: (onSubmit: (value: V) => Promise<unknown>) => void };
  connector: UseDialogConnector<T, V>;
}

export function useDialog<T, V = void>(): UseDialogResult<T, V> {
  const [config, setConfig] = useState<T | null>(null);
  const onSubmitRef = useRef<((value: V) => Promise<unknown>) | null>(null);

  function open(cfg: T): { then: (onSubmit: (value: V) => Promise<unknown>) => void } {
    setConfig(cfg);

    return {
      then(onSubmit: (value: V) => Promise<unknown>) {
        onSubmitRef.current = onSubmit;
      },
    };
  }

  return {
    open,
    connector: {
      config,
      close: () => setConfig(null),
      submit: (value: V) => onSubmitRef.current!(value),
    },
  };
}
