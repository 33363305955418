import Box from '@mui/material/Box';
import React from 'react';

type Props = {
  primaryText: string;
  secondaryText?: string;
};

export const DropdownOptionLabel: React.FC<Props> = ({ primaryText, secondaryText }: Props) => {
  return (
    <>
      <Box textAlign="left" flexGrow={1}>
        {primaryText}
      </Box>
      {secondaryText && <Box color="grey">{secondaryText}</Box>}
    </>
  );
};
