import { OrganisationDetails } from '../domain/Organisation';

export function buildEmailSignature(organisationDetails?: OrganisationDetails) {
  if (!organisationDetails) {
    return 'n/a';
  }

  const address = organisationDetails.address;

  return [
    organisationDetails.name ?? organisationDetails.organisation,
    address?.line1,
    address?.line2,
    address?.line3,
    address?.line4,
    address ? `${address?.town}, ${organisationDetails.address?.postcode}` : null,
  ]
    .filter((e) => e != null)
    .join('\n');
}
