import { useQuery } from '@tanstack/react-query';
import { loadClinicians } from '../../services/AppApi';
import { CLINICIANS_QUERY_KEY, CLINICIANS_QUERY_STALE_TIME } from '../../util/queries';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function useClinicians() {
  const { organisation: organisationDetails } = useCurrentOrganisationDetails();
  const { organisation, ehr } = organisationDetails ?? { organisation: undefined, ehr: undefined };

  const { data, isPending, isLoading, error } = useQuery({
    queryKey: [CLINICIANS_QUERY_KEY, organisation],
    queryFn: async () =>
      Object.fromEntries(
        (await loadClinicians(organisation!, ehr!)).data.clinicians.map(({ gp_id, gp_name }) => [gp_id, gp_name]),
      ),
    staleTime: CLINICIANS_QUERY_STALE_TIME,
    retry: false,
    enabled: !!organisation && !!ehr,
  });

  return {
    clinicians: data,
    loading: isPending || isLoading,
    error,
  };
}
