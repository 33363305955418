export const SCHEDULED_MESSAGING_MORE_INFO_URL = 'https://docs.abtrace-cloud.com/admin/automated-messaging/';

export const PATIENT_NAME_PLACEHOLDER = '[Patient Name]';
export const APPOINTMENT_LINK_SECTION_EMAIL = 'Click here to book an appointment:';
export const APPOINTMENT_LINK_PLACEHOLDER = '[Appointment Link]';
export const SMS_PATIENT_NAME_PLACEHOLDER_ALLOCATED_LENGTH = 30;

export const EMAIL = 'EMAIL';
export const SMS = 'SMS';

export const EMAIL_AND_SMS = 'EMAIL_AND_SMS';
export const EMAIL_OR_SMS = 'EMAIL_OR_SMS';
export const SMS_OR_EMAIL = 'SMS_OR_EMAIL';
export const EMAIL_ONLY = 'EMAIL_ONLY';
export const SMS_ONLY = 'SMS_ONLY';

export type MessageType = typeof EMAIL | typeof SMS;

export type SendPreference =
  | typeof EMAIL_AND_SMS
  | typeof EMAIL_OR_SMS
  | typeof SMS_OR_EMAIL
  | typeof EMAIL_ONLY
  | typeof SMS_ONLY;

export enum MessageRecordingChoice {
  FreeText = 'freetext',
  Attachment = 'attachment',
}

export interface RuleOrganisationConfig {
  enabled: boolean;
  patientSelectionCriteria: {
    maxDefinedByPractice: number;
    maxAllowedByAbtrace: number;
    minDaysBetweenMessagingRounds: number;
    maxMessagingRounds: number;
    doubleBooking?: {
      tooRecent: {
        enabled: boolean;
        days: number;
      };
      tooSoon: {
        enabled: boolean;
        days: number;
      };
    };
  };
  preference?: SendPreference;
  emailTemplate?: {
    subject: string;
    content: string;
  };
  smsTemplate?: { message: string };
  appointments: {
    addInvitationLink: boolean;
    description?: string;
    selfBooking?: {
      bookableWithinDays: number;
      slotTypes: string[];
    };
  };
  messageRecordingChoice: MessageRecordingChoice;
  extraCodes: string[];
  schedule: {
    daysOfWeek: ('MON' | 'TUE' | 'WED' | 'THU' | 'FRI')[];
    hour: number;
  };
  patientNeedsSummary?: boolean;
  staffNote?: string | null;
}

export interface ScheduledMessagingOrganisationConfig {
  organisation: string;
  available: boolean;
  reportsRecipientUserId?: string;
  partnerApiCredentials?: {
    username: string;
    password: string;
  };
  rules?: Record<string, RuleOrganisationConfig>;
}

export type PatientStatus = 'green' | 'grey' | 'yellow' | 'orange' | 'red' | 'black';

export type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export type SortByColumn = 'nhs_number' | 'status' | 'days_overdue' | 'dob';

export interface Rule {
  id: string;
  name: string;
  description: string;
  actions?: string[];
  indications?: string[];
  excludedIndications?: string[];
  statuses?: PatientStatus[];
  monthOfBirth?: Month;
  daysOverdue?: { from: number; to: number };
  age?: { from: number; to: number };
  gpIds?: string[];
  sortBy?: {
    column: SortByColumn;
    order: 'ASC' | 'DESC';
  };
}

export const RUNNING = 'RUNNING';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';

export type RuleExecutionStatus = typeof RUNNING | typeof FAILURE | typeof SUCCESS;

export interface RuleExecutions {
  executions: RuleExecutionReport[];
  nextScheduledRun?: string;
}

export interface RuleExecutionSummaryReport {
  matchedPatients: number;
  selected: number;
  emailed?: number;
  texted?: number;
  unreachable: number;
  patientRecordUpdateErrors: number;
}

export interface RuleExecutionReport {
  executionId: string;
  status: RuleExecutionStatus;
  startedAt: Date;
  finishedAt?: Date;
  ruleSnapshot: RuleSnapshot;
  ruleConfigSnapshot: Omit<RuleOrganisationConfig, 'schedule'> & { schedule?: RuleOrganisationConfig['schedule'] };
  reportEmailed?: boolean;
  reportS3Path?: string;
  summary?: RuleExecutionSummaryReport;
  error?: string;
}

export interface RuleSnapshot {}

export interface RuleStats {
  matchingPatients: number;
}
