import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import { useQueryClient } from '@tanstack/react-query';
import { updateOnSiteEncryption } from '../../services/AppApi';
import { QUERY_STRING_ORGANISATION_DETAILS } from '../../util/constants';
import { OrgDetails } from './helpers';

type Props = {
  organisationId: string;
  value: boolean;
  ehr: 'emis' | 'tpp';
};

export const OnSiteEncryptionCheckbox: React.FC<Props> = ({ organisationId, value, ehr }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.checked;

    try {
      await updateOnSiteEncryption(organisationId, updatedValue);

      queryClient.setQueryData([QUERY_STRING_ORGANISATION_DETAILS], (previousConfig: any) => {
        return previousConfig.map((org: OrgDetails) =>
          org.id === organisationId ? { ...org, onSiteEncryption: updatedValue } : org,
        );
      });
      dispatch(enqueueInfoNotification('On site encryption configuration updated'));
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  };

  return <Checkbox disabled={ehr === 'emis'} checked={value} onChange={onChange} />;
};
