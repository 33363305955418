import React from 'react';
import { AppointmentBookingConfig } from '../AppointmentBookingConfig';
import { MessagingStats } from './MessagingStats/MessagingStats';
import { MessagingConfig } from './MessagingConfig/MessagingConfig';

interface Props {
  organisation: string;
}

export function Messaging({ organisation }: Props) {
  return (
    <>
      <MessagingConfig />
      <AppointmentBookingConfig organisation={organisation} />
      <MessagingStats />
    </>
  );
}
