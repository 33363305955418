import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  SCHEDULED_MESSAGING_RULES_QUERY_KEY,
  SCHEDULED_MESSAGING_QUERY_STALE_TIME,
  SCHEDULED_MESSAGING_QUERY_KEY,
} from '../../util/queries';
import { Rule, ScheduledMessagingOrganisationConfig } from '../../domain/ScheduledMessaging';
import * as ScheduledMessagingApi from '../../services/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseRulesResult {
  rules?: Record<string, Rule>;
  loading: boolean;
  error: unknown;
  removeRule: (ruleId: string) => Promise<void>;
}

export function useRules(): UseRulesResult {
  const queryClient = useQueryClient();

  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const { data, isLoading, error } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_RULES_QUERY_KEY],
    queryFn: async () => {
      const rules = await ScheduledMessagingApi.loadRules();
      return Object.fromEntries(rules.map((rule) => [rule.id, rule]));
    },
    staleTime: SCHEDULED_MESSAGING_QUERY_STALE_TIME,
    retry: false,
  });

  const removeRuleMutation = useMutation({
    mutationFn: async (ruleId: string) => {
      const orgConfig = await ScheduledMessagingApi.removeRule(organisation, ruleId);

      return [ruleId, orgConfig] as const;
    },
    onSuccess: (result: readonly [ruleId: string, ScheduledMessagingOrganisationConfig]) => {
      const [ruleId, orgConfig] = result;

      queryClient.setQueryData<Record<string, Rule>>([SCHEDULED_MESSAGING_RULES_QUERY_KEY], (rules) => {
        if (!rules) return rules;

        const { [ruleId]: removed, ...rest } = rules;

        return rest;
      });

      queryClient.setQueryData([SCHEDULED_MESSAGING_QUERY_KEY, organisation], orgConfig);
    },
  });

  async function removeRule(ruleId: string) {
    await removeRuleMutation.mutateAsync(ruleId);
  }

  return {
    rules: data,
    loading: isLoading,
    error,
    removeRule,
  };
}
