import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Invitation } from '../../../domain/Invitation';

interface Props {
  invitations: Invitation[];
  deleteInvitation: (email: string) => void;
}

export default function OrganisationInvitationsTable({ invitations, deleteInvitation }: Props) {
  if (invitations.length === 0) {
    return <Typography variant="body1">No invitations found!</Typography>;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User e-mail</TableCell>
            <TableCell>Invited by</TableCell>
            <TableCell>Expires at</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((invitation) => (
              <TableRow key={invitation.email}>
                <TableCell>{invitation.email}</TableCell>
                <TableCell>{invitation.invitedBy}</TableCell>
                <TableCell>
                  {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                    new Date(invitation.expiresAt)
                  )}
                </TableCell>
                <TableCell>
                  <Button onClick={() => deleteInvitation(invitation.email)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
