import React, { useContext } from 'react';
import Stack from '@mui/material/Stack';
import { PartnerApiCredentials } from '../PartnerApiCredentials/PartnerApiCredentials';
import { ScheduledMessagingOrganisationConfig } from '../../../../domain/ScheduledMessaging';
import { ReportsUserSelect } from '../ReportsUserSelect/ReportsUserSelect';
import userContext from '../../../../context/UserContext';
import { AvailabilityCheckbox } from '../AvailabilityCheckbox/AvailabilityCheckbox';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface Props {
  config?: ScheduledMessagingOrganisationConfig;
  onAvailableChange: (enabled: boolean) => void;
  onReportsRecipientUserIdChange: (userId: string | undefined) => Promise<void>;
  onUpdatePartnerApiCredentials: (username: string, password: string) => Promise<void>;
}

export function OrganisationLevelConfig({
  config,
  onAvailableChange,
  onReportsRecipientUserIdChange,
  onUpdatePartnerApiCredentials,
}: Props) {
  const { organisation } = useCurrentOrganisationDetails();
  const { available, reportsRecipientUserId, partnerApiCredentials } = config ?? { available: false };

  const context = useContext(userContext);

  const user = context.user;
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canAdmin = isSuperUser || isSupportUser;

  return (
    <Stack gap={2}>
      <AvailabilityCheckbox enabled={canAdmin} available={available} onChange={onAvailableChange} />

      {available && (
        <>
          <ReportsUserSelect
            reportsRecipientUserId={reportsRecipientUserId}
            onReportsRecipientUserIdChange={onReportsRecipientUserIdChange}
          />

          {organisation.ehr === 'emis' && (
            <PartnerApiCredentials
              username={partnerApiCredentials?.username}
              password={partnerApiCredentials?.password}
              updateCredentials={onUpdatePartnerApiCredentials}
            />
          )}
        </>
      )}
    </Stack>
  );
}
