import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import EmailField, { Props } from '../EmailField/EmailField';

const ALLOWED_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+(@(.+\.)?(nhs.net|nhs.uk|abtrace.co))$/;

export default function NhsNetEmailField(props: Props & Omit<TextFieldProps, 'error'>) {
  return (
    <EmailField
      {...props}
      rules={{
        ...props.rules,
        pattern: {
          value: ALLOWED_EMAIL_REGEX,
          message: 'Please enter a valid NHS e-mail',
        },
      }}
    />
  );
}
