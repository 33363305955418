import React from 'react';
import { User } from '../domain/User';

export interface UserContext {
  user: User | null;
  updateCurrentUser: (user?: User) => void;
  updateIsVerified: (value: boolean) => void;
  loaded: boolean;
  isVerified: boolean;
}

const userContext = React.createContext<UserContext>({
  user: null,
  isVerified: false,
  updateCurrentUser: () => {},
  updateIsVerified: () => {},
  loaded: false,
});

export default userContext;
