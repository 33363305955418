import Chip from '@mui/material/Chip';
import React from 'react';
import { PatientStatus } from '../../../../domain/ScheduledMessaging';

interface Props {
  status: PatientStatus;
}

export function PatientStatusChip({ status }: Props) {
  return status === 'green' ? (
    <Chip label="Complete" color="success" size="small" />
  ) : status === 'grey' ? (
    <Chip label="Complete" sx={{ color: '#fff', backgroundColor: '#777' }} size="small" />
  ) : status === 'yellow' ? (
    <Chip label="Opportunistic" color="warning" size="small" />
  ) : status === 'orange' ? (
    <Chip label="Opportunistic" color="warning" size="small" />
  ) : status === 'red' ? (
    <Chip label="Overdue" color="error" size="small" />
  ) : (
    <Chip label="Critically overdue" color="primary" size="small" />
  );
}
