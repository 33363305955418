import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserApplicationsTable from '../UserApplicationsTable/UserApplicationsTable';
import { Application } from '../../../domain/Application';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { ApplicationRequest } from '../ApplicationRequest/ApplicationRequest';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';
import { useApplications } from '../../../hooks/useApplications/useApplications';

export default function UserApplications() {
  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const { applicationsQuery, withdraw, apply } = useApplications();

  const { data: applications, error } = applicationsQuery;

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load applications for user', error));
    }
  }, [error]);

  const { loading, organisations } = useOrganisationDetails(applicationsQuery, ({ organisation }) => organisation);

  async function onWithdraw(application: Application) {
    try {
      return await withdraw(application);
    } catch (err) {
      dispatch(
        enqueueErrorNotification(
          `Unable to withdraw the application to join ${organisationLabel(application.organisation, organisations!)}`,
          err
        )
      );
    }
  }

  function askBeforeWithdrawing(application: Application) {
    open({
      title: 'Withdraw application',
      text: `Are you sure you want to withdraw your application to join ${organisationLabel(
        application.organisation,
        organisations!
      )}?`,
    }).then(() => onWithdraw(application));
  }

  return (
    <>
      {loading ? (
        <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress data-testid="spinner" />
        </Backdrop>
      ) : (applications ?? []).length === 0 ? (
        <Typography variant="body1">You have no applications.</Typography>
      ) : (
        <>
          <Typography variant="body1" mt={3} mb={2}>
            You applied to the following organisations:
          </Typography>
          <UserApplicationsTable
            applications={applications ?? []}
            organisations={organisations!}
            onWithdraw={askBeforeWithdrawing}
          />
        </>
      )}

      <Box mt={2} textAlign="right">
        <ApplicationRequest onNewApplication={apply} />
      </Box>

      <ConfirmationDialog connector={connector} />
    </>
  );
}
