import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SCHEDULED_MESSAGING_QUERY_KEY, SCHEDULED_MESSAGING_QUERY_STALE_TIME } from '../../util/queries';
import { RuleOrganisationConfig, ScheduledMessagingOrganisationConfig } from '../../domain/ScheduledMessaging';
import * as ScheduledMessagingApi from '../../services/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseScheduledMessagingConfigResult {
  config?: ScheduledMessagingOrganisationConfig;
  loading: boolean;
  error: unknown;
  updateOrganisationConfig: (payload: UpdateOrganisationLevelConfigPayload) => Promise<void>;
  updateRuleConfig: (ruleId: string, ruleConfig: RuleOrganisationConfig) => Promise<void>;
  removeRuleConfig: (ruleId: string) => Promise<void>;
}

interface UpdateOrganisationLevelConfigPayload {
  available?: boolean;
  reportsRecipientUserId?: string | null;
  partnerApiCredentials?: { username: string; password: string };
}

interface UpdateRuleConfigPayload {
  ruleId: string;
  ruleConfig: RuleOrganisationConfig;
}

export function useScheduledMessagingConfig(): UseScheduledMessagingConfigResult {
  const queryClient = useQueryClient();

  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const { data, isLoading, error } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_QUERY_KEY, organisation],
    queryFn: () => ScheduledMessagingApi.loadScheduledMessagingConfig(organisation),
    staleTime: SCHEDULED_MESSAGING_QUERY_STALE_TIME,
    retry: false,
  });

  const updateOrganisationConfigMutation = useMutation({
    mutationFn: ({ available, reportsRecipientUserId, partnerApiCredentials }: UpdateOrganisationLevelConfigPayload) =>
      ScheduledMessagingApi.updateScheduledMessagingOrganisationConfig(
        organisation,
        available,
        reportsRecipientUserId,
        partnerApiCredentials
      ),
    onSuccess: (result) => {
      queryClient.setQueryData<ScheduledMessagingOrganisationConfig>(
        [SCHEDULED_MESSAGING_QUERY_KEY, organisation],
        result as ScheduledMessagingOrganisationConfig
      );
    },
  });

  const updateRuleConfigMutation = useMutation({
    mutationFn: ({ ruleId, ruleConfig }: UpdateRuleConfigPayload) =>
      ScheduledMessagingApi.updateRuleConfig(organisation, ruleId, ruleConfig),
    onSuccess: (result) => {
      queryClient.setQueryData<ScheduledMessagingOrganisationConfig>(
        [SCHEDULED_MESSAGING_QUERY_KEY, organisation],
        result as ScheduledMessagingOrganisationConfig
      );
    },
  });

  const removeRuleConfigMutation = useMutation({
    mutationFn: ({ ruleId }: { ruleId: string }) => ScheduledMessagingApi.removeRuleConfig(organisation, ruleId),
    onSuccess: (result) => {
      queryClient.setQueryData<ScheduledMessagingOrganisationConfig>(
        [SCHEDULED_MESSAGING_QUERY_KEY, organisation],
        result as ScheduledMessagingOrganisationConfig
      );
    },
  });

  async function updateOrganisationConfig(payload: UpdateOrganisationLevelConfigPayload): Promise<void> {
    await updateOrganisationConfigMutation.mutateAsync(payload);
  }

  async function updateRuleConfig(ruleId: string, ruleConfig: RuleOrganisationConfig): Promise<void> {
    await updateRuleConfigMutation.mutateAsync({ ruleId, ruleConfig });
  }

  async function removeRuleConfig(ruleId: string): Promise<void> {
    await removeRuleConfigMutation.mutateAsync({ ruleId });
  }

  return {
    config: data,
    loading: isLoading,
    error,
    updateOrganisationConfig,
    updateRuleConfig,
    removeRuleConfig,
  };
}
