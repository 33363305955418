import { OrganisationDetails } from '../../domain/Organisation';

export function getOrgLabel(org: OrganisationDetails) {
  const { name, organisation } = org;

  return name ? `${name} (${organisation})` : organisation;
}

export function organisationLabel(code: string, details: Record<string, OrganisationDetails | null>): string {
  const org = details[code];

  if (!org) return code;

  return getOrgLabel(org);
}
