import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../util/constants';

export interface Props {
  name: string;
  control: Control<FieldValues>;
  error?: FieldError;
  rules: any;
}

export default function EmailField({
  name,
  control,
  error,
  rules,
  ...inputProps
}: Props & Omit<TextFieldProps, 'error'>) {
  const invalidEmailMessage = rules?.invalid ?? 'invalid.email';

  if (rules) {
    delete rules.invalid;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          onChange={onChange}
          value={value}
          error={!!error?.type}
          helperText={error?.message}
          {...inputProps}
        />
      )}
      rules={{
        pattern: {
          value: EMAIL_REGEX,
          message: invalidEmailMessage,
        },
        ...rules,
      }}
      defaultValue=""
    />
  );
}
