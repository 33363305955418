import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ConfigContext from '../../../../context/ConfigContext';
import { parseIdToken, User } from '../../../../domain/User';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import PasswordField from '../../../shared/PasswordField/PasswordField';
import { confirmSignIn, fetchAuthSession, getCurrentUser, signIn } from 'aws-amplify/auth';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  email: string;
  password: string;
  onPasswordChanged: (user: User) => void;
  dispatch: Dispatch;
}

const ChangePasswordForm = ({ email, password, onPasswordChanged }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const config = useContext(ConfigContext);

  const updateErrorMessage = (errorMessage: string) => {
    dispatch(enqueueErrorNotification(errorMessage));
  };

  async function changePassword({ newPassword, newPasswordConfirmation }: FieldValues) {
    if (newPassword !== newPasswordConfirmation) {
      updateErrorMessage('The passwords do not match');
      reset({
        newPassword: '',
        newPasswordConfirmation: '',
      });
      return;
    }

    setSubmitting(true);

    try {
      await signIn({
        username: email,
        password,
        options: { service: config.datadog.service },
      });

      await confirmSignIn({ challengeResponse: newPassword });

      const currentUser = await getCurrentUser();
      const session = await fetchAuthSession();

      const jwtPayload = session.tokens?.idToken?.payload;

      const user = {
        email: jwtPayload?.email! as string,
        firstName: (jwtPayload?.given_name as string) ?? '',
        lastName: (jwtPayload?.family_name as string) ?? '',
        role: (jwtPayload?.['custom:role'] as string) ?? '',
        organisations: parseIdToken(session.tokens?.idToken?.toString()!),
        userId: currentUser.username,
        superUser: jwtPayload?.['custom:super_user'] === 'true',
        supportUser: jwtPayload?.['custom:support_user'] === 'true',
      };

      onPasswordChanged(user);
    } catch (error) {
      updateErrorMessage(`Unable to change password: ${error instanceof Error ? error.message : new String(error)}`);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <form>
      <Typography variant="h5" gutterBottom>
        Change your password
      </Typography>

      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <PasswordField
          id="newPassword"
          name="newPassword"
          control={control}
          error={errors.newPassword as FieldError}
          label="New password"
          autoFocus={true}
          required
          rules={{ required: 'New password is required' }}
        />

        <PasswordField
          id="newPasswordConfirmation"
          name="newPasswordConfirmation"
          control={control}
          error={errors.newPasswordConfirmation as FieldError}
          label="Confirm new password"
          required
          rules={{ required: 'Password confirmation is required' }}
        />

        <LoadingButton
          data-testid="update-password"
          variant="contained"
          color="primary"
          onClick={handleSubmit(changePassword)}
          loading={submitting}
        >
          Update password
        </LoadingButton>
      </FormControl>
    </form>
  );
};

export default connect()(ChangePasswordForm);
