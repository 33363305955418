import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import TextField from '../../../shared/TextField/TextField';
import { buildSmsSignature, bulkSmsLength, getMaxLengthForEncoding } from '../../../../util/messages';
import { APPOINTMENT_LINK_PLACEHOLDER } from '../../../../domain/ScheduledMessaging';
import ConfigContext from '../../../../context/ConfigContext';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function SmsContent() {
  const {
    appointments: { appointmentBookingUrlFormat },
  } = useContext(ConfigContext);

  const { organisation } = useCurrentOrganisationDetails();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const signature = buildSmsSignature(organisation!);

  const [appointmentsAddInvitationLink, message] = watch(['appointmentsAddInvitationLink', 'message']);

  function getSmsLength() {
    const { encoding, length } = bulkSmsLength(
      message,
      signature,
      appointmentsAddInvitationLink,
      appointmentBookingUrlFormat.length,
    );
    return { length, maxLength: getMaxLengthForEncoding(encoding) };
  }

  const [size, setSize] = useState(() => getSmsLength());

  useEffect(() => {
    setSize(getSmsLength());
  }, [appointmentsAddInvitationLink, message, signature]);

  return (
    <>
      <Stack gap={1}>
        <Stack gap={0.5}>
          <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
            <em>Dear ⟦Patient Name⟧,</em>
          </Typography>
          <TextField
            name="message"
            control={control}
            error={errors.message as FieldError}
            label="message"
            required
            multiline
            rules={{
              required: 'Message is required',
              validate: () => {
                const { length, maxLength } = getSmsLength();
                return length <= maxLength ? true : `Maximum of ${maxLength} characters allowed`;
              },
            }}
            variant="standard"
            rows={5}
          />
          {appointmentsAddInvitationLink && (
            <Typography color="text.secondary" variant="body2" sx={{ userSelect: 'none' }}>
              <em>{APPOINTMENT_LINK_PLACEHOLDER}</em>
            </Typography>
          )}
        </Stack>
        <Typography color="text.secondary" variant="body2" display="inline" data-field="sms-signature">
          {signature}
        </Typography>
        <Typography
          textAlign="right"
          variant="caption"
          color={size.length > size.maxLength ? 'error' : 'text.primary'}
          data-field="smsLength"
        >
          Number of characters: {size.length}/{size.maxLength}
        </Typography>
      </Stack>
    </>
  );
}
