export interface OrganisationContactDetails extends Record<string, string> {
  email: string;
  phone: string;
  url: string;
  urlDescription: string;
  address: string;
}

export enum PatientTaskStatusFieldNamesEnum {
  overdue = 'overdueStatusDescription',
  upToDate = 'upToDateStatusDescription',
  dueBy = 'dueByStatusDescription',
  active = 'active',
}

export interface PatientTasksDefaultDescriptionPerStatus extends Record<string, string> {
  [PatientTaskStatusFieldNamesEnum.overdue]: string;
  [PatientTaskStatusFieldNamesEnum.upToDate]: string;
  [PatientTaskStatusFieldNamesEnum.dueBy]: string;
  genericStatusDescription: string;
}

export interface PatientTaskConfigurationItem extends Record<string, string | boolean> {
  code: string;
  [PatientTaskStatusFieldNamesEnum.active]: boolean;
  [PatientTaskStatusFieldNamesEnum.overdue]: string;
  [PatientTaskStatusFieldNamesEnum.upToDate]: string;
  [PatientTaskStatusFieldNamesEnum.dueBy]: string;
}

export interface PatientTasksConfiguration extends Record<string, boolean | PatientTaskConfigurationItem[]> {
  active: boolean;
  tasks: PatientTaskConfigurationItem[];
}

export interface PatientTaskCategory extends Record<string, string> {
  code: string;
  name: string;
}
