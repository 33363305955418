import React, { useEffect } from 'react';
import Box from '@mui/system/Box';
import { EmailConfig } from './EmailConfig';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import { useAppointmentBookingConfig } from '../../../hooks/useAppointmentBookingConfig/useAppointmentBookingConfig';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';

type Props = {
  organisation: string;
};

export const AppointmentBookingConfig: React.FC<Props> = ({ organisation }) => {
  const dispatch = useDispatch();

  const { config: appointmentsConfig, loading, error, updateEmail, verifyEmail } = useAppointmentBookingConfig();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load the appointments configuration', error));
    }
  }, [error]);

  async function onEmailUpdate(organisationEmail: string) {
    try {
      await updateEmail(organisationEmail);
      dispatch(enqueueInfoNotification('Please check your inbox for a verification code'));
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to update email address', err));
    }
  }

  async function onVerifyEmail(verificationCode: string) {
    try {
      await verifyEmail(verificationCode);
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to verify email', err));
    }
  }

  return (
    <LoadingSection loading={loading}>
      {appointmentsConfig && (
        <>
          <Box pl={2} pt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            {appointmentsConfig.enabled && (
              <EmailConfig
                organisation={organisation}
                email={appointmentsConfig.organisationEmail?.address ?? null}
                verified={appointmentsConfig.organisationEmail?.verified ?? false}
                onEmailUpdate={onEmailUpdate}
                onEmailVerify={onVerifyEmail}
              />
            )}
          </Box>
        </>
      )}
    </LoadingSection>
  );
};
