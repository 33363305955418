export class ApiError extends Error {
  public readonly cause: unknown;

  public readonly code?: string;

  public readonly traceId?: string;

  constructor(
    message: string,
    props: {
      cause: unknown;
      code?: string;
      traceId?: string;
    },
  ) {
    super(message);
    this.name = 'ApiError';

    const cause = props.cause;
    this.cause =
      cause instanceof Error
        ? {
            ...cause,
            name: cause.name,
            message: cause.message,
            stack: cause.stack,
          }
        : cause;
    this.code = props?.code;
    this.traceId = props.traceId;
  }
}
