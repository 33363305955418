import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { ApiError } from '../../services/ApiError';
import { TIMEOUT } from '../../util/constants';

export interface Notification {
  message: SnackbarMessage;
  options: OptionsObject;
}

export type NotificationState = {
  notifications: Notification[];
};

const SNACK_BAR_HIDE_DURATION = {
  SUCCESS: 5000,
  INFO: 5000,
};

const initialState: NotificationState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<Notification>) => {
      // Delete all notifications for the same key EXCEPT the first, because
      // presumably that's the one currently being displayed.
      // This is to avoid spamming the user with too many simultaneous
      // notifications which would just be displayed for a few milliseconds
      // before being replaced by another notification with the same key
      const [notifications] = state.notifications.reduce(
        ([acc, foundKey], notification) => {
          if (foundKey && notification.options.key === action.payload.options.key) {
            return [acc, foundKey];
          }
          return [[...acc, notification], foundKey || notification.options.key === action.payload.options.key];
        },
        [[] as Notification[], false],
      );

      return {
        notifications: [
          ...notifications,
          {
            message: action.payload.message,
            options: action.payload.options,
          },
        ],
      };
    },
    removeNotification: (state, action: PayloadAction<{ key: SnackbarKey; message: SnackbarMessage }>) => {
      let notifications = state.notifications;
      if (typeof action.payload.key === 'number') {
        // If the key is a number, this must be a notification originally
        // created without a key (and the number was generated by notistack).
        // In this case, we want to remove the first notification from the
        // store with no key and the same message, because that was the
        // notification that was displayed.
        const index = state.notifications.findIndex(
          ({ options, message }) => typeof options.key === 'undefined' && message === action.payload.message,
        );

        if (index > -1) {
          notifications = [...state.notifications];
          notifications.splice(index, 1);
        }
      } else if (typeof action.payload.key === 'string') {
        // If the key is a string, the notification was created with a key and
        // we want to remove the first notification with the same key and message,
        // because presumably that was the one that was displayed.
        // Note that multiple notifications with the same key and message are
        // totally possible (but only one will be displayed at a time)
        const index = state.notifications.findIndex(
          ({ options, message }) => options.key === action.payload.key && message === action.payload.message,
        );

        if (index > -1) {
          notifications = [...state.notifications];
          notifications.splice(index, 1);
        }
      }

      return {
        notifications,
      };
    },
  },
});

export const { enqueueNotification, removeNotification } = notificationsSlice.actions;

export function enqueueInfoNotification(message: SnackbarMessage, options: OptionsObject = {}) {
  return enqueueNotification({
    message,
    options: {
      ...options,
      variant: 'info',
      autoHideDuration: SNACK_BAR_HIDE_DURATION.INFO,
    },
  });
}

export function enqueueSuccessNotification(message: SnackbarMessage, options: OptionsObject = {}) {
  return enqueueNotification({
    message,
    options: {
      ...options,
      variant: 'success',
      autoHideDuration: SNACK_BAR_HIDE_DURATION.SUCCESS,
    },
  });
}

export function enqueueWarningNotification(message: SnackbarMessage, options: OptionsObject = {}) {
  return enqueueNotification({
    message,
    options: {
      ...options,
      variant: 'warning',
      autoHideDuration: null,
    },
  });
}

export function enqueueErrorNotification(message: SnackbarMessage, error?: unknown, options: OptionsObject = {}) {
  let fullMessage = message;

  if (error instanceof Error) {
    if (error instanceof ApiError && error.code === TIMEOUT) {
      // Avoid duplicate 'Please confirm that you are on the correct network' notifications
      fullMessage = error.message;
    } else {
      fullMessage = `${message}: ${error.message}`;
    }
  }

  return enqueueNotification({
    message: fullMessage,
    options: {
      ...options,
      variant: 'error',
      autoHideDuration: null,
    },
  });
}

export default notificationsSlice.reducer;
