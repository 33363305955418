import { AxiosInstance } from 'axios';
import { MessagingConfig } from '../domain/Messaging';
import { MESSAGING_API_NAME } from '../util/constants';
import { callApi, getApiClient, resetApiClient } from '../util/api';

function client(): AxiosInstance {
  return getApiClient(MESSAGING_API_NAME);
}

export async function loadMessagingConfig(organisation: string): Promise<MessagingConfig | null> {
  return callApi(client().get, `/organisations/${organisation}/messages/config`, undefined, undefined, true);
}

export async function updateMessagingConfig(
  organisation: string,
  payload: { singleMessagingEnabled?: boolean; bulkMessagingEnabled?: boolean; limits?: { sms: number } },
): Promise<void> {
  return callApi(client().patch, `/organisations/${organisation}/messages/config`, payload) as Promise<void>;
}

export function resetClient() {
  resetApiClient(MESSAGING_API_NAME);
}
