import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import * as Api from '../../../services/Api';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import { useQuery } from '@tanstack/react-query';
import { OTHER_ORGANISATIONS_QUERY_KEY, OTHER_ORGANISATIONS_QUERY_STALE_TIME } from '../../../util/queries';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';

interface Props {
  exclude: string[];
}

export default function OtherOrganisations({ exclude }: Props) {
  const dispatch = useDispatch();

  const otherOrganisationsQuery = useQuery({
    queryKey: [OTHER_ORGANISATIONS_QUERY_KEY],
    queryFn: async () => {
      return Api.loadAllOrganisations().then(({ organisations }) => {
        const otherOrganisations = new Set(organisations);
        for (const organisation of exclude) {
          otherOrganisations.delete(organisation);
        }

        return Array.from(otherOrganisations);
      });
    },
    retry: false,
    staleTime: OTHER_ORGANISATIONS_QUERY_STALE_TIME,
  });

  const { error } = otherOrganisationsQuery;

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load other organisations', error));
    }
  }, [error]);

  const { loading, organisations } = useOrganisationDetails(otherOrganisationsQuery);

  return (
    <LoadingSection loading={loading}>
      {Object.keys(organisations ?? {}).length === 0 ? (
        <Typography variant="body1">There are no organisations you&apos;re not a member of</Typography>
      ) : (
        <>
          <Typography variant="body1">Additional organisations you&apos;re not a member of:</Typography>
          <List sx={{ maxWidth: 360 }}>
            {[...Object.keys(organisations!)]
              .sort((a, b) => a.localeCompare(b))
              .map((id) => (
                <ListItem key={id} component={Link} to={`/organisations/${id}`}>
                  <ListItemButton>
                    <ListItemText primary={organisationLabel(id, organisations ?? {})} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </>
      )}
    </LoadingSection>
  );
}
