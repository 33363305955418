import { count, Encoding } from 'sms-length';
import { escapeRegExp } from './regexp';
import { OrganisationDetails } from '../domain/Organisation';
import {
  PATIENT_NAME_PLACEHOLDER,
  APPOINTMENT_LINK_PLACEHOLDER,
  SMS_PATIENT_NAME_PLACEHOLDER_ALLOCATED_LENGTH,
} from '../domain/ScheduledMessaging';

interface SmsLength {
  encoding: Encoding;
  length: number;
  characterPerMessage: number;
  inCurrentMessage: number;
  remaining: number;
  messages: number;
}

export function getMaxLengthForEncoding(encoding: Encoding) {
  switch (encoding) {
    case 'GSM_7BIT':
    case 'GSM_7BIT_EXT':
      return 612;
    case 'UTF16':
      return 264;
  }
}

export function buildSmsMessage(content: string, invitationUrl: string | null, signature: string) {
  return `${content}${invitationUrl ? `\n${invitationUrl.substring('https://'.length)}` : ''}\n${signature}`;
}

export function smsLength(
  content: string,
  signature: string,
  shouldAddAppointmentBookingLink: boolean,
  appointmentBookingUrlLength?: number,
): SmsLength {
  const dummyInvitationUrl =
    shouldAddAppointmentBookingLink && appointmentBookingUrlLength ? '_'.repeat(appointmentBookingUrlLength) : '';

  return count(buildSmsMessage(content, dummyInvitationUrl, signature));
}

export function buildSmsSignature(organisationDetails?: OrganisationDetails) {
  return organisationDetails?.name ?? organisationDetails?.organisation ?? 'n/a';
}

export function buildBulkSmsMessage(content: string, signature: string, shouldAddAppointmentBookingLink: boolean) {
  return `Dear ${PATIENT_NAME_PLACEHOLDER},\n${content}\n${
    shouldAddAppointmentBookingLink ? `${APPOINTMENT_LINK_PLACEHOLDER}\n` : ''
  }${signature}`;
}

export function bulkSmsLength(
  content: string,
  signature: string,
  shouldAddAppointmentBookingLink: boolean,
  appointmentBookingUrlLength?: number,
): SmsLength {
  const dummyInvitationUrl =
    shouldAddAppointmentBookingLink && appointmentBookingUrlLength
      ? '_'.repeat(appointmentBookingUrlLength - 'https://'.length)
      : APPOINTMENT_LINK_PLACEHOLDER;

  return count(
    buildBulkSmsMessage(content, signature, shouldAddAppointmentBookingLink)
      .replace(
        new RegExp(escapeRegExp(PATIENT_NAME_PLACEHOLDER), 'g'),
        '_'.repeat(SMS_PATIENT_NAME_PLACEHOLDER_ALLOCATED_LENGTH),
      )
      .replace(new RegExp(escapeRegExp(APPOINTMENT_LINK_PLACEHOLDER), 'g'), dummyInvitationUrl),
  );
}
