import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Application } from '../../../domain/Application';
import { ApplicationStatusChip } from '../../applications/ApplicationStatusChip/ApplicationStatusChip';

interface Props {
  applications: Application[];
  approveApplication: (application: Application) => void;
  denyApplication: (application: Application) => void;
}

export default function OrganisationApplicationsTable({ applications, approveApplication, denyApplication }: Props) {
  if (applications.length === 0) {
    return <Typography variant="body1">No applications found!</Typography>;
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User e-mail</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Reviewer</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...applications]
              .sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
              .map((application) => (
                <TableRow key={application.id}>
                  <TableCell>{application.email}</TableCell>
                  <TableCell>
                    <ApplicationStatusChip status={application.status} />
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                      new Date(application.createdAt),
                    )}
                  </TableCell>
                  <TableCell>{application.reviewer}</TableCell>
                  <TableCell>
                    {application.updatedAt &&
                      new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                        new Date(application.updatedAt),
                      )}
                  </TableCell>
                  <TableCell>
                    {application.status === 'PENDING' && (
                      <>
                        <Button onClick={() => approveApplication(application)}>Approve</Button>
                        <Button onClick={() => denyApplication(application)}>Deny</Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
