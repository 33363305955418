import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import React from 'react';
import { ReactNode } from 'react';

interface Props {
  loading: boolean;
  children: ReactNode;
}

export function LoadingSection({ loading, children }: Props) {
  return loading ? (
    <Box ml={2} mt={2}>
      <Typography>
        <CircularProgress data-testid="spinner" size="0.8em" /> Loading...
      </Typography>
    </Box>
  ) : (
    <>{children}</>
  );
}
