import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { Invitation } from '../../../domain/Invitation';
import { OrganisationDetails } from '../../../domain/Organisation';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';

interface Props {
  invitations: Invitation[];
  organisations: Record<string, OrganisationDetails | null>;
  onAccept: (organisation: string) => Promise<void>;
  onDecline: (organisation: string) => void;
}

function formatDate(date: string): string {
  return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date));
}

function UserInvitation({
  invitation,
  organisations,
  onAccept,
  onDecline,
}: {
  invitation: Invitation;
  organisations: Record<string, OrganisationDetails | null>;
  onAccept: (organisation: string) => Promise<void>;
  onDecline: (organisation: string) => void;
}) {
  const [accepting, setAccepting] = useState(false);

  const accept = async () => {
    setAccepting(true);
    try {
      await onAccept(invitation.organisation);
    } finally {
      setAccepting(false);
    }
  };

  return (
    <ListItem key={invitation.organisation}>
      <Grid container spacing="8" alignItems={'center'}>
        <Grid item xs>
          <ListItemText
            primary={organisationLabel(invitation.organisation, organisations)}
            secondary={
              <>
                <div>{`Invited by: ${invitation.invitedBy}`}</div>
                <div>{`Expires at: ${formatDate(invitation.expiresAt)}`}</div>
              </>
            }
            secondaryTypographyProps={{ component: 'div' }}
          />
        </Grid>
        <Grid item xs="auto">
          <Stack direction="row" spacing={1}>
            <LoadingButton onClick={accept} variant="contained" loading={accepting}>
              Accept
            </LoadingButton>
            <Button onClick={() => onDecline(invitation.organisation)} disabled={accepting}>
              Decline
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default function UserInvitationsList({ invitations, organisations, onAccept, onDecline }: Props) {
  return (
    <>
      <List>
        {invitations
          .sort((a, b) => a.organisation.localeCompare(b.organisation))
          .map((invitation) => (
            <UserInvitation
              key={invitation.organisation}
              invitation={invitation}
              organisations={organisations}
              onAccept={onAccept}
              onDecline={onDecline}
            />
          ))}
      </List>
    </>
  );
}
