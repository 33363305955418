import React, { useContext, useEffect, useState } from 'react';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import Typography from '@mui/material/Typography';
import { RuleDefinition } from '../RuleDefinition/RuleDefinition';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import userContext from '../../../../context/UserContext';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRuleStats } from '../../../../hooks/useRuleStats/useRuleStats';
import Tooltip from '@mui/material/Tooltip';

export function RuleOverviewTab() {
  const { getRequiredSelectedRule, removeRuleConfig, removeRule } = useContext(rulesContext);

  const [isRemovingConfig, setRemovingConfig] = useState(false);
  const [isRemovingRule, setRemovingRule] = useState(false);

  const { id: ruleId, definition: rule, config } = getRequiredSelectedRule();

  const { stats, calculatingStats, calculateStats, statsError } = useRuleStats(ruleId);

  const { open, connector } = useDialog<ConfirmationDialogConfig, void>();

  const { user } = useContext(userContext);
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canRemoveStuff = isSuperUser || isSupportUser;
  const canRemoveRule = canRemoveStuff && !config.enabled;

  const dispatch = useDispatch();

  useEffect(() => {
    if (statsError) {
      dispatch(enqueueErrorNotification('Unable to calculate the number of matching patients', statsError));
    }
  }, [statsError]);

  function verifyConfigRemoval() {
    open({
      title: 'Rule config removal',
      text: `Are you sure you want to remove the config for rule ${rule.name} from this organisation?`,
    }).then(async () => {
      setRemovingConfig(true);

      try {
        await removeRuleConfig(ruleId);
        dispatch(enqueueSuccessNotification('Rule config removed'));
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to remove the rule config', error));
      } finally {
        setRemovingConfig(false);
      }
    });
  }

  function verifyRuleRemoval() {
    open({
      title: 'Rule removal',
      text: `This will remove the actual global rule, not just the configuration associated with the organisation.
             Are you sure you want to remove the rule ${rule.name} and its associated configuration from this organisation?`,
    }).then(async () => {
      setRemovingRule(true);

      try {
        await removeRule(ruleId);
        dispatch(enqueueSuccessNotification('Rule removed'));
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to remove the rule', error));
      } finally {
        setRemovingRule(false);
      }
    });
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {rule.name}
      </Typography>

      <RuleDefinition rule={rule} />

      <Box my={2} width="467px">
        <Alert severity="info" icon={false}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>Number of patients matching the rule:</Typography>
            <Typography>
              <strong>{stats ? Number(stats!.matchingPatients).toLocaleString() : 'Undetermined'}</strong>
            </Typography>

            <Tooltip title="Refresh number">
              <IconButton color="info" disabled={calculatingStats} onClick={calculateStats}>
                <RefreshIcon
                  role={calculatingStats ? 'progressbar' : ''}
                  sx={
                    calculatingStats
                      ? {
                          animation: 'spin 1s linear infinite',
                          '@keyframes spin': {
                            '100%': {
                              transform: 'rotate(360deg)',
                            },
                          },
                        }
                      : {}
                  }
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Alert>
      </Box>

      {canRemoveStuff && (
        <>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" gap={2}>
            <LoadingButton
              loading={isRemovingConfig}
              variant="contained"
              onClick={verifyConfigRemoval}
              startIcon={<DeleteIcon />}
            >
              Remove rule config
            </LoadingButton>

            <LoadingButton
              loading={isRemovingRule}
              variant="contained"
              onClick={verifyRuleRemoval}
              disabled={!canRemoveRule}
              startIcon={<DeleteIcon />}
            >
              Remove rule
            </LoadingButton>
          </Box>

          <ConfirmationDialog connector={connector} />
        </>
      )}
    </>
  );
}
