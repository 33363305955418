import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { loadUserlessOrganisations } from '../../../services/Api';
import { OrganisationDetails } from '../../../domain/Organisation';
import {
  OTHER_ORGANISATIONS_QUERY_KEY,
  USERLESS_ORGANISATIONS_QUERY_KEY,
  OTHER_ORGANISATIONS_QUERY_STALE_TIME,
} from '../../../util/queries';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';

export const useUserlessOrgs = () => {
  const orgIdsQuery = useQuery({
    queryKey: [OTHER_ORGANISATIONS_QUERY_KEY, USERLESS_ORGANISATIONS_QUERY_KEY],
    queryFn: async () => {
      const data = await loadUserlessOrganisations();
      const ids = data.organisations;

      return ids;
    },
    retry: false,
    staleTime: OTHER_ORGANISATIONS_QUERY_STALE_TIME,
  });
  const { loading, organisations: orgDetailsById } = useOrganisationDetails(orgIdsQuery);
  const data = useMemo(() => {
    const res: OrganisationDetails[] = [];

    if (!orgDetailsById) return res;

    Object.entries(orgDetailsById).forEach(([orgId, orgDetails]) => {
      if (!orgDetails) {
        res.push({ organisation: orgId });
      }

      if (orgDetails) res.push(orgDetails);
    });

    return res.sort((a, b) => a.organisation.localeCompare(b.organisation));
  }, [orgDetailsById]);

  return { loading, data };
};
