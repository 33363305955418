import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { RuleConfigurationTab } from '../RuleConfigurationTab/RuleConfigurationTab';
import { RuleExecutionsTab } from '../RuleExecutionsTab/RuleExecutionsTab';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import Alert from '@mui/material/Alert';
import { RuleOverviewTab } from '../RuleOverviewTab/RuleOverviewTab';

export function RuleDetails() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { selectedRule, hasUnsavedChanges } = useContext(rulesContext);

  useEffect(() => {
    setSelectedTab(0);
  }, [selectedRule?.id]);

  return (
    <>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Overview" disabled={!selectedRule || hasUnsavedChanges} />
        <Tab label="Configuration" disabled={!selectedRule} />
        <Tab label="Executions" disabled={!selectedRule || hasUnsavedChanges} />
      </Tabs>
      <Box sx={(theme) => ({ padding: theme.spacing(3) })}>
        {selectedRule ? (
          <>
            {selectedTab === 0 && <RuleOverviewTab />}
            {selectedTab === 1 && <RuleConfigurationTab />}
            {selectedTab === 2 && <RuleExecutionsTab />}
          </>
        ) : (
          <Box maxWidth={500}>
            <Alert severity="warning">Please select a rule on the left to see details</Alert>
          </Box>
        )}
      </Box>
    </>
  );
}
