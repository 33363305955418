import { useQuery } from '@tanstack/react-query';
import { loadFrontendDefinitions } from '../../services/AppApi';
import { FRONTEND_DEFINITIONS_QUERY_KEY, FRONTEND_DEFINITIONS_QUERY_STALE_TIME } from '../../util/queries';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function useFrontendDefinitions() {
  const { organisation: organisationDetails } = useCurrentOrganisationDetails();

  const organisation = organisationDetails?.organisation;

  const { data, isPending, isLoading, error } = useQuery({
    queryKey: [FRONTEND_DEFINITIONS_QUERY_KEY, organisation],
    queryFn: async () => loadFrontendDefinitions(organisation!),
    staleTime: FRONTEND_DEFINITIONS_QUERY_STALE_TIME,
    retry: false,
    enabled: !!organisation,
  });

  return {
    frontendDefinitions: data,
    loading: isPending || isLoading,
    error,
  };
}
