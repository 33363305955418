import React, { useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RulesList } from '../RulesList/RulesList';
import { RuleDetails } from '../RuleDetails/RuleDetails';
import { Stack } from '@mui/system';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';

export function RulesSection() {
  const { loading, error } = useContext(rulesContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load scheduled messaging rules', error));
    }
  }, [error]);

  return (
    <Stack>
      <Typography variant="h6">Rules</Typography>
      <LoadingSection loading={loading}>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <Box
            sx={(theme) => ({
              width: '20%',
              marginRight: theme.spacing(2),
            })}
          >
            <RulesList />
          </Box>
          <Box sx={{ width: '80%' }}>
            <RuleDetails />
          </Box>
        </Box>
      </LoadingSection>
    </Stack>
  );
}
