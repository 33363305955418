import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ConfigContext from '../../../../context/ConfigContext';
import { OrganisationContactDetails } from '../../../../domain/PatientFacing';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../../redux/reducers/notificationsReducer';
import * as PatientFacingConfigApi from '../../../../services/PatientFacingConfigApi';
import { EMAIL_REGEX } from '../../../../util/constants';
import { currentTheme } from '../../../../util/theme';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { NoDataToDisplay } from '../../../shared/NoDataToDisplay/NoDataToDisplay';

interface Props {
  organisation: string;
  enabled: boolean;
}

const phoneExtension = '+44';

const formatContactDetailsFormValues = (rawFormValues: OrganisationContactDetails): OrganisationContactDetails => {
  const { email, phone, url, urlDescription, address } = rawFormValues;

  // Prepend the phone extension ONLY if there is a phone number defined
  const phoneNumber = !!phone.trim() ? `${phoneExtension}${phone.trim()}` : '';

  return {
    email,
    phone: phoneNumber,
    url,
    urlDescription,
    address,
  };
};

export function PatientContactDetails({ organisation, enabled }: Props) {
  const dispatch = useDispatch();
  const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);
  const [urlFieldIsEmpty, setUrlFieldIsEmpty] = useState<boolean>(true);
  const {
    patientFacingConfigurations: { patientWebsiteContactPreviewPageUrl },
  } = useContext(ConfigContext);
  const {
    handleSubmit,
    formState: { isLoading, isSubmitting },
    control,
    watch,
    setValue,
    setError,
    resetField,
    getFieldState,
    getValues,
  } = useForm({
    defaultValues: async () => {
      try {
        const currentConfigs = await PatientFacingConfigApi.loadContactUsPageConfig(organisation);
        setErrorLoadingData(false);

        // Remove the extension (which for now we know it's +44)
        let phone = '';
        const rawPhoneNumber = currentConfigs?.phone;
        if (rawPhoneNumber) {
          phone = rawPhoneNumber.slice(3);
        }

        const url = currentConfigs?.url ?? '';
        setUrlFieldIsEmpty(url === '');

        // Note: default to empty values if the API returns null
        // (meaning the organisation does not yet exist there!)
        return {
          phone,
          url,
          email: currentConfigs?.email ?? '',
          urlDescription: currentConfigs?.urlDescription ?? '',
          address: currentConfigs?.address ?? '',
        };
      } catch (error) {
        setErrorLoadingData(true);
        dispatch(
          enqueueErrorNotification(
            `Unable to load patient facing configurations for organisation ${organisation}`,
            error,
          ),
        );
        return;
      }
    },
  });

  // Set the urlDescription field to empty when the url field is empty
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'url' && type === 'change')
        if (value.url === '') {
          setUrlFieldIsEmpty(true);
          setValue('urlDescription', '');
        } else {
          setUrlFieldIsEmpty(false);
        }
      return subscription.unsubscribe;
    });
  }, [watch]);

  const submitForm = (fieldName: string) => async (formValues: FieldValues) => {
    // If the field was not modified, there is no need to save
    if (!getFieldState(fieldName).isDirty) {
      return;
    }

    const payload = formatContactDetailsFormValues(formValues as OrganisationContactDetails);

    try {
      await PatientFacingConfigApi.saveContactUsPageConfig(organisation, payload);
      dispatch(enqueueInfoNotification(`Updated patient facing configurations for organisation ${organisation}`));

      // Reset the field state so that we can evaluate further changes (isDirty)
      resetField(fieldName, { defaultValue: getValues(fieldName) });
    } catch (error: any) {
      dispatch(
        enqueueErrorNotification(
          `Unable to save patient facing configurations for organisation ${organisation}`,
          error,
        ),
      );

      // Set the field errors in the form, if they exist
      if (error.cause?.response?.data?.issues?.length) {
        error.cause.response.data.issues.forEach((issue) => {
          setError(issue.path, { message: issue.message });
        });
      }
    }
  };

  return (
    <LoadingSection loading={isLoading}>
      <Backdrop open={isSubmitting} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
      {errorLoadingData && <NoDataToDisplay />}
      {!errorLoadingData && (
        <FormGroup
          sx={{
            maxWidth: '850px',
            paddingTop: '30px',
          }}
        >
          <FormControl fullWidth margin="normal">
            <Stack direction="row" spacing={5}>
              <Container disableGutters>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextField
                      label="Practice Email"
                      name={name}
                      type="email"
                      fullWidth
                      onChange={onChange}
                      value={value}
                      disabled={!enabled}
                      error={!!error}
                      helperText={error?.message}
                      onBlur={handleSubmit(submitForm(name))}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email',
                    },
                  }}
                />
              </Container>
              <Container disableGutters>
                <Stack direction="row" spacing={2}>
                  <Typography variant="button" sx={{ color: 'gray', mt: 'auto !important', mb: 'auto !important' }}>
                    {phoneExtension}
                  </Typography>

                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <TextField
                        label="Practice Phone"
                        name={name}
                        type="number"
                        fullWidth
                        onChange={onChange}
                        value={value}
                        disabled={!enabled}
                        error={!!error}
                        helperText={error?.message}
                        onBlur={handleSubmit(submitForm(name))}
                      />
                    )}
                  />
                </Stack>
              </Container>
            </Stack>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField
                  label="Address"
                  name={name}
                  type="text"
                  onChange={onChange}
                  value={value}
                  disabled={!enabled}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={handleSubmit(submitForm(name))}
                />
              )}
            />
          </FormControl>
          <Divider sx={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px', width: '65%' }} />
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="url"
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField
                  label="Practice Website URL (e.g. Contact Us page)"
                  name={name}
                  type="url"
                  onChange={onChange}
                  value={value}
                  disabled={!enabled}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={handleSubmit(submitForm(name))}
                />
              )}
              rules={{
                validate: (value) => {
                  // Allow empty strings
                  if (value === '') {
                    return true;
                  }

                  // Reject values not started with http/https
                  if (!value.startsWith('http://') && !value.startsWith('https://')) {
                    return 'Must start with "http://" or "https://"';
                  }

                  // Make sure it's a valid URL
                  try {
                    new URL(value);
                    return true;
                  } catch (_) {
                    return 'Invalid url';
                  }
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              control={control}
              name="urlDescription"
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextField
                  label="URL Description"
                  name={name}
                  type="text"
                  onChange={onChange}
                  value={value}
                  disabled={!enabled || urlFieldIsEmpty}
                  error={!!error}
                  helperText={error?.message ?? 'This displays to patient only if a URL is set'}
                  onBlur={handleSubmit(submitForm(name))}
                />
              )}
            />
          </FormControl>

          <Container disableGutters sx={{ width: 'auto', marginRight: 0 }}>
            <Link
              component="button"
              variant="subtitle1"
              sx={{ color: currentTheme.palette.nhsPrimary.main, textDecorationColor: 'inherit' }}
              onClick={() => {
                const url = new URL(patientWebsiteContactPreviewPageUrl);
                const formValues = formatContactDetailsFormValues(getValues() as OrganisationContactDetails);
                Object.entries(formValues).forEach(([key, value]) => {
                  url.searchParams.set(key, value);
                });

                window.open(url.href, '_blank', 'noopener');
              }}
            >
              Preview Patient Contact Page
            </Link>
          </Container>
        </FormGroup>
      )}
    </LoadingSection>
  );
}
