import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Invitation } from '../../../domain/Invitation';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import * as Api from '../../../services/Api';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { InviteDialog } from '../InviteDialog/InviteDialog';
import OrganisationInvitationsTable from '../OrganisationInvitationsTable/OrganisationInvitationsTable';

interface Props {
  organisation: string;
}

export default function OrganisationInvitations({ organisation }: Props) {
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const dispatch = useDispatch();

  const { open: openDeleteConfirmationDialog, connector: confirmationDialogConnector } =
    useDialog<ConfirmationDialogConfig>();
  const { open: openInvitationDialog, connector: invitationDialogConnector } = useDialog<{}, string>();

  useEffect(() => {
    Api.loadOrganisationInvitations(organisation)
      .then((newInvitations) => {
        setInvitations(newInvitations);
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to load invitations for organisation ${organisation}`, error));
      })
      .finally(() => setLoading(false));
  }, [organisation]);

  const deleteInvitation = (email: string) => {
    return Api.deleteInvitation(email, organisation)
      .then(() => {
        setInvitations(invitations.filter((invitation) => invitation.email !== email));
        dispatch(enqueueInfoNotification(`Deleted invitation for ${email}`));
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to delete invitation for ${email}`, error));
      });
  };

  const askBeforeDeletingInvitation = (email: string) => {
    openDeleteConfirmationDialog({
      title: 'Delete invitation',
      text: `Are you sure you want to delete the invitation for ${email}?`,
    }).then(() => deleteInvitation(email));
  };

  const onInvite = (email: string) =>
    Api.invite(email, organisation)
      .then((invitation) => {
        setInvitations(invitations.filter((inv) => inv.email !== invitation.email).concat(invitation));
        dispatch(enqueueInfoNotification(`Invited ${email}`));
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to invite ${email}`, error));
        throw error;
      });

  const openInviteDialog = () => {
    openInvitationDialog({}).then((email) => onInvite(email));
  };

  return (
    <LoadingSection loading={loading}>
      <OrganisationInvitationsTable invitations={invitations} deleteInvitation={askBeforeDeletingInvitation} />

      <Box mt={2} textAlign="right">
        <Button variant="contained" onClick={openInviteDialog}>
          Invite users
        </Button>

        <InviteDialog connector={invitationDialogConnector} />
        <ConfirmationDialog connector={confirmationDialogConnector} />
      </Box>
    </LoadingSection>
  );
}
