import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { getOrgLabel } from '../../../util/organisationLabel/organisationLabel';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { useUserlessOrgs } from './useUserlessOrgs';

export default function UserlessOrganisations() {
  const { loading, data } = useUserlessOrgs();

  return (
    <LoadingSection loading={loading}>
      {data.length < 1 ? (
        <Typography variant="body1">There are no organisations without users</Typography>
      ) : (
        <List sx={{ maxWidth: 360 }}>
          {data.map((org) => {
            const { organisation } = org;

            return (
              <ListItem key={organisation} component={Link} to={`/organisations/${organisation}`}>
                <ListItemButton>
                  <ListItemText primary={getOrgLabel(org)} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </LoadingSection>
  );
}
