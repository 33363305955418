import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import userContext from '../../../../context/UserContext';
import { SmsAllowanceSlider } from '../SmsAllowanceSlider/SmsAllowanceSlider';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { useMessagingConfig } from '../../../../hooks/useMessagingConfig/useMessagingConfig';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function MessagingConfig() {
  const context = useContext(userContext);
  const user = context.user;
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canEditSingleMessaging = isSuperUser || isSupportUser;
  const canEditBulkMessaging = isSuperUser || isSupportUser;
  const canEditSmsAllowance = isSuperUser || isSupportUser;

  const dispatch = useDispatch();

  const { config, loading, error, updateConfig } = useMessagingConfig();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load the messaging configuration', error));
    }
  }, [error]);

  async function onSingleMessagingChange(checked: boolean) {
    setSaving(true);
    try {
      await updateConfig({ singleMessagingEnabled: checked });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    } finally {
      setSaving(false);
    }
  }

  async function onBulkMessagingChange(checked: boolean) {
    setSaving(true);
    try {
      await updateConfig({ bulkMessagingEnabled: checked });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    } finally {
      setSaving(false);
    }
  }

  async function onSaveSmsLimit(limit: number) {
    try {
      await updateConfig({ limits: { sms: limit } });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  }

  return (
    <LoadingSection loading={loading}>
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
      <Box pl={2} pt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={config?.config.singleMessagingEnabled}
              disabled={!canEditSingleMessaging}
              onChange={(e) => onSingleMessagingChange(e.target.checked)}
            />
          }
          label={<Typography variant="body1">Allow users to send single messages to patients</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={config?.config.bulkMessagingEnabled}
              disabled={!canEditBulkMessaging}
              onChange={(e) => onBulkMessagingChange(e.target.checked)}
            />
          }
          label={<Typography variant="body1">Allow users to send bulk messages to patients</Typography>}
        />
      </Box>

      <SmsAllowanceSlider
        limit={config?.stats.SMS.limit}
        saveSmsLimit={(newLimit) => onSaveSmsLimit(newLimit)}
        enabled={canEditSmsAllowance}
      />
    </LoadingSection>
  );
}
