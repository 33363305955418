import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PendingUserApplicationsList from '../PendingUserApplicationsList/PendingUserApplicationsList';
import { Application } from '../../../domain/Application';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationRequest } from '../ApplicationRequest/ApplicationRequest';
import SidePanel from '../../shared/SidePanel/SidePanel';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';
import { useApplications } from '../../../hooks/useApplications/useApplications';

export default function PendingUserApplicationsPanel() {
  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const { applicationsQuery, withdraw, apply } = useApplications({ pending: true });

  const { data: pendingApplications, error } = applicationsQuery;

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load applications for user', error));
    }
  }, [error]);

  const { loading, organisations } = useOrganisationDetails(applicationsQuery, ({ organisation }) => organisation);

  async function onWithdraw(application: Application) {
    try {
      return await withdraw(application);
    } catch (err) {
      dispatch(
        enqueueErrorNotification(
          `Unable to withdraw the application to join ${organisationLabel(application.organisation, organisations!)}`,
          err
        )
      );
    }
  }

  function askBeforeWithdrawing(application: Application) {
    open({
      title: 'Withdraw application',
      text: `Are you sure you want to withdraw your application to join ${organisationLabel(
        application.organisation,
        organisations!
      )}?`,
    }).then(() => onWithdraw(application));
  }

  return (
    <>
      <SidePanel title="Pending applications">
        <LoadingSection loading={loading}>
          {(pendingApplications ?? []).length === 0 ? (
            <>
              <Box ml={2} mt={2}>
                <Typography variant="body1">You have no pending applications.</Typography>
              </Box>
            </>
          ) : (
            <>
              <PendingUserApplicationsList
                applications={pendingApplications ?? []}
                organisations={organisations!}
                onWithdraw={askBeforeWithdrawing}
              />
            </>
          )}
          <Box ml={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2" component="span">
                <Link to="/applications" component={RouterLink}>
                  See all
                </Link>
              </Typography>

              <ApplicationRequest compact={true} onNewApplication={apply} />
            </Stack>
          </Box>
        </LoadingSection>
      </SidePanel>

      <ConfirmationDialog connector={connector} />
    </>
  );
}
