import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { useMessagingConfig } from '../../../../hooks/useMessagingConfig/useMessagingConfig';

export function MessagingStats() {
  const { config, loading } = useMessagingConfig();

  return (
    !loading && (
      <Stack mt={4} ml={2} gap={4} direction="row">
        <Typography>Messages sent this month:</Typography>
        <Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ paddingTop: 0 }}>
                    E-Mails
                  </TableCell>
                  <TableCell align="center" sx={{ paddingTop: 0 }}>
                    SMS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" data-testid="emails-sent">
                    {config?.stats ? Number(config.stats.EMAIL.sent).toLocaleString() : 'n/a'}
                  </TableCell>
                  <TableCell align="center" data-testid="sms-sent">
                    {config?.stats ? Number(config.stats.SMS.sent).toLocaleString() : 'n/a'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    )
  );
}
