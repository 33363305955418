import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import { SCHEDULED_MESSAGING_MORE_INFO_URL } from '../../../../domain/ScheduledMessaging';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  enabled: boolean;
  available: boolean;
  onChange: (checked: boolean) => void;
};

export const AvailabilityCheckbox: React.FC<Props> = ({ enabled, available, onChange }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={available}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            disabled={!enabled}
          />
        }
        label={
          <Typography variant="body1" color={enabled ? 'text.primary' : 'text.disabled'}>
            Practice admins can configure Scheduled Messaging
          </Typography>
        }
      />

      <Link variant="body2" target="_blank" underline="always" href={SCHEDULED_MESSAGING_MORE_INFO_URL} fontSize="1rem">
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography>More information</Typography>
          <OpenInNewIcon sx={{ fontSize: '16px' }} />
        </Stack>
      </Link>
    </Stack>
  );
};
