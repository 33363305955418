import { useQuery } from '@tanstack/react-query';
import {
  SCHEDULED_MESSAGING_RULE_STATS_QUERY_KEY,
  SCHEDULED_MESSAGING_RULE_STATS_QUERY_STALE_TIME,
} from '../../util/queries';
import { RuleStats } from '../../domain/ScheduledMessaging';
import { loadRuleStats } from '../../services/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseRuleStatsResult {
  stats?: RuleStats;
  calculatingStats: boolean;
  statsError: unknown;
  calculateStats: () => Promise<unknown>;
}

export function useRuleStats(ruleId: string): UseRuleStatsResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const { data, error, refetch, isFetching } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_RULE_STATS_QUERY_KEY, organisation, ruleId],
    queryFn: async () => {
      return loadRuleStats(organisation, ruleId);
    },
    staleTime: SCHEDULED_MESSAGING_RULE_STATS_QUERY_STALE_TIME,
    retry: false,
    enabled: false, // Disabled because retrieval is done on demand
  });

  return {
    stats: data,
    calculatingStats: isFetching,
    statsError: error,
    calculateStats: refetch,
  };
}
