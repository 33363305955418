import { USER_APPLICATIONS_QUERY_KEY } from '../../util/queries';
import * as Api from '../../services/Api';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Application } from '../../domain/Application';

export interface UseApplicationsResult {
  applicationsQuery: UseQueryResult<Application[]>;
  apply: (organisation: string) => Promise<void>;
  withdraw: (application: Application) => Promise<void>;
}

export function useApplications({ pending }: { pending?: boolean } = { pending: false }): UseApplicationsResult {
  function applyFilters(applications: Application[]) {
    return applications.filter((application) => !pending || application.status === 'PENDING');
  }

  const queryClient = useQueryClient();

  const applicationsQuery = useQuery({
    queryKey: [USER_APPLICATIONS_QUERY_KEY],
    queryFn: () => Api.loadUserApplications().then(applyFilters),
    retry: false,
  });

  const applyMutation = useMutation({
    mutationFn: (organisation: string) => Api.apply(organisation),
    onSuccess: (application: Application) => {
      queryClient.setQueryData([USER_APPLICATIONS_QUERY_KEY], (previousApplications: Application[] | undefined) =>
        (previousApplications ?? []).concat(application)
      );
    },
  });

  const withdrawApplicationMutation = useMutation({
    mutationFn: (application: Application) => Api.withdrawApplication(application),
    onSuccess: (withdrawn) => {
      queryClient.setQueryData<Application[]>(
        [USER_APPLICATIONS_QUERY_KEY],
        (previousApplications: Application[] | undefined) => {
          const updatedApplications = previousApplications!.map((app) => {
            if (app.status === 'PENDING' && app.id === withdrawn.id) {
              return withdrawn;
            } else {
              return app;
            }
          });

          return applyFilters(updatedApplications);
        }
      );
    },
  });

  async function withdraw(application: Application): Promise<void> {
    await withdrawApplicationMutation.mutateAsync(application);
  }

  async function apply(organisation: string): Promise<void> {
    await applyMutation.mutateAsync(organisation);
  }

  return { applicationsQuery, withdraw, apply };
}
