import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { Application } from '../../../domain/Application';
import { OrganisationDetails } from '../../../domain/Organisation';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { ApplicationStatusChip } from '../ApplicationStatusChip/ApplicationStatusChip';

interface Props {
  applications: Application[];
  organisations: Record<string, OrganisationDetails | null>;
  onWithdraw: (application: Application) => void;
}

export default function UserApplicationsTable({ applications, organisations, onWithdraw }: Props) {
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Organisation</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Reviewer</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...applications]
              .sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
              .map((application) => (
                <TableRow key={application.id}>
                  <TableCell>{organisationLabel(application.organisation, organisations)}</TableCell>
                  <TableCell>
                    <ApplicationStatusChip status={application.status} />
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                      new Date(application.createdAt)
                    )}
                  </TableCell>
                  <TableCell>{application.reviewer}</TableCell>
                  <TableCell>
                    {application.updatedAt &&
                      new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                        new Date(application.updatedAt)
                      )}
                  </TableCell>
                  <TableCell>
                    {application.status === 'PENDING' && (
                      <Button size="small" onClick={() => onWithdraw(application)}>
                        Withdraw
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
