import Chip from '@mui/material/Chip';
import React from 'react';
import { RuleExecutionStatus } from '../../../../../domain/ScheduledMessaging';

interface Props {
  status: RuleExecutionStatus;
}

export function ExecutionStatusChip({ status }: Props) {
  return status === 'SUCCESS' ? (
    <Chip color="success" label="Success" />
  ) : status === 'FAILURE' ? (
    <Chip color="error" label="Failed" />
  ) : (
    <Chip color="info" label="Running" />
  );
}
