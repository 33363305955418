import Alert from '@mui/material/Alert';
import React, { useContext } from 'react';
import userContext from '../../../context/UserContext';

export function SuperUserAlert() {
  const context = useContext(userContext);
  const user = context.user;

  if (!user?.superUser) {
    return <></>;
  }

  return (
    <Alert severity="warning" variant="filled">
      You have unrestricted access
    </Alert>
  );
}
