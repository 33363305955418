import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React from 'react';
import { EmailContent } from '../EmailContent/EmailContent';
import { SmsContent } from '../SmsContent/SmsContent';
import { Accordion, AccordionDetails, AccordionSummary } from './styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useFormContext } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export function MessageAccordion() {
  const { watch, setValue } = useFormContext();

  const [sendEmails, sendTexts] = watch(['sendEmails', 'sendTexts']);

  function onChange(field: string, expanded: boolean) {
    setValue(field, expanded, { shouldDirty: true });
  }

  return (
    <>
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={sendTexts}
        onChange={(_ev, expanded) => onChange('sendTexts', expanded)}
        slotProps={{
          transition: { unmountOnExit: true },
        }}
      >
        <AccordionSummary expandIcon={<KeyboardArrowRightIcon />}>
          <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center" gap={1}>
              {sendTexts ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              <Typography variant="subtitle1">Send SMS</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <SmsContent />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={sendEmails}
        onChange={(_ev, expanded) => onChange('sendEmails', expanded)}
        slotProps={{
          transition: { unmountOnExit: true },
        }}
      >
        <AccordionSummary expandIcon={<KeyboardArrowRightIcon />}>
          <Stack direction="row" alignItems="center" gap={1}>
            {sendEmails ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            <Typography variant="subtitle1">Send E-Mail</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <EmailContent />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
