import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserInvitationsList from '../UserInvitationsList/UserInvitationsList';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { useNavigate } from 'react-router-dom';
import PrimarySidePanel from '../../shared/PrimarySidePanel/PrimarySidePanel';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';
import { useInvitations } from '../../../hooks/useInvitations/useInvitations';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function UserInvitationsPanel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const { invitationsQuery, accept, decline } = useInvitations();

  const { data: invitations, error } = invitationsQuery;

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load pending invitations for user', error));
    }
  }, [error]);

  const { organisations, loading } = useOrganisationDetails(invitationsQuery, ({ organisation }) => organisation);

  const onAccept = async (organisation: string) => {
    try {
      await accept(organisation);
      await fetchAuthSession({ forceRefresh: true });
      dispatch(enqueueInfoNotification(`Joined ${organisationLabel(organisation, organisations ?? {})}`));

      const remainingInvitations = invitations!.filter((invitation) => invitation.organisation !== organisation);
      if (remainingInvitations.length === 0) {
        navigate(`/organisations/${organisation}`);
      }
    } catch (err) {
      dispatch(
        enqueueErrorNotification(
          `Unable to accept the invitation to join ${organisationLabel(organisation, organisations ?? {})}`,
          err,
        ),
      );
    }
  };

  const onDecline = async (organisation: string) => {
    try {
      await decline(organisation);
      dispatch(
        enqueueInfoNotification(
          `Declined the invitation to join ${organisationLabel(organisation, organisations ?? {})}`,
        ),
      );
    } catch (err) {
      dispatch(
        enqueueErrorNotification(
          `Unable to decline the invitation to join ${organisationLabel(organisation, organisations ?? {})}`,
          err,
        ),
      );
    }
  };

  const askBeforeDeclining = (organisation: string) => {
    open({
      title: 'Decline invitation',
      text: `Are you sure you want to decline the invitation for ${organisationLabel(
        organisation,
        organisations ?? {},
      )}?`,
    }).then(() => onDecline(organisation));
  };

  return (
    <>
      {!!invitations && invitations.length > 0 && !loading && (
        <PrimarySidePanel title="Pending invitations">
          <UserInvitationsList
            invitations={invitations}
            organisations={organisations ?? {}}
            onAccept={onAccept}
            onDecline={askBeforeDeclining}
          />
        </PrimarySidePanel>
      )}

      <ConfirmationDialog connector={connector} />
    </>
  );
}
