import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

export function DoubleBookingConfiguration() {
  const { control } = useFormContext();

  return (
    <Stack gap={1}>
      <Controller
        name="doubleBookingTooRecentEnabled"
        control={control}
        render={({ field: { onChange: onTooRecentEnabledChange, value: onTooRecentEnabled } }) => (
          <Stack>
            <FormControlLabel
              control={<Checkbox checked={onTooRecentEnabled} size="small" onChange={onTooRecentEnabledChange} />}
              label={
                <Typography display="flex" alignContent="center" alignItems="center" component="div">
                  Do not message patients who&apos;ve had an appointment:
                </Typography>
              }
            />

            <Controller
              name="doubleBookingTooRecentsDays"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Typography display="flex" alignContent="center" alignItems="center" component="div" marginLeft="27px">
                  In the last
                  <Box mx={1}>
                    <TextField
                      type="number"
                      value={value}
                      disabled={!onTooRecentEnabled}
                      onChange={onChange}
                      size="small"
                      inputProps={{ sx: { width: '60px' }, min: 1, 'aria-label': 'Max days ago' }}
                    />
                  </Box>
                  day(s)
                </Typography>
              )}
            />
          </Stack>
        )}
      />

      <Controller
        name="doubleBookingTooSoonEnabled"
        control={control}
        render={({ field: { onChange: onTooSoonEnabledChange, value: onTooSoonEnabled } }) => (
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onTooSoonEnabled}
                  size="small"
                  onChange={(...args) => onTooSoonEnabledChange(...args)}
                />
              }
              label={
                <Typography display="flex" alignContent="center" alignItems="center" component="div">
                  Do not message patients who have an appointment booked:
                </Typography>
              }
            />

            <Controller
              name="doubleBookingTooSoonDays"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Typography display="flex" alignContent="center" alignItems="center" component="div" marginLeft="27px">
                  For the next
                  <Box mx={1}>
                    <TextField
                      type="number"
                      value={value}
                      disabled={!onTooSoonEnabled}
                      onChange={onChange}
                      size="small"
                      inputProps={{ sx: { width: '60px' }, min: 1, 'aria-label': 'Max days ahead' }}
                    />
                  </Box>
                  day(s)
                </Typography>
              )}
            />
          </Stack>
        )}
      />
    </Stack>
  );
}
