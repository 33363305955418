import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import userContext from '../../../context/UserContext';
import { SuperUserAlert } from '../SuperUserAlert/SuperUserAlert';
import { UserMenu } from '../UserMenu/UserMenu';

export default function NavBar() {
  const location = useLocation();
  const context = useContext(userContext);
  const user = context.user;

  let tabs = [
    { value: 'home', label: 'Home', to: '/' },
    { value: 'applications', label: 'Applications', to: '/applications' },
  ];

  if (user?.superUser || user?.supportUser) {
    tabs = tabs.concat({ value: 'abtrace', label: 'Abtrace', to: '/abtrace' });
  }

  const currentTab = tabs.find((tab) => tab.to !== '/' && location.pathname.startsWith(tab.to)) || tabs[0];

  return (
    <AppBar position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar>
        <Tabs sx={{ flexGrow: 1 }} value={currentTab.value} textColor="inherit">
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} component={Link} to={tab.to} />
          ))}
        </Tabs>
        <SuperUserAlert />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}
