import React from 'react';
import NavBar from '../../components/navigation/NavBar/NavBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { loadAllOrganisations } from '../../services/Api';
import { useOrganisationDetails } from '../../hooks/useOrganisationDetails/useOrganisationDetails';
import { OrganisationList } from './OrganisationList';
import { loadOrganisationDetails } from './helpers';
import { QUERY_STRING_ORGANISATION_DETAILS } from '../../util/constants';
import { OTHER_ORGANISATIONS_QUERY_KEY } from '../../util/queries';

export const Overview: React.FC = () => {
  const { data: organisationsData, isLoading: isOrganisationDataLoading } = useQuery({
    queryKey: [OTHER_ORGANISATIONS_QUERY_KEY],
    queryFn: () => loadAllOrganisations(),
  });
  const { organisations } = useOrganisationDetails(organisationsData?.organisations ?? []);

  const { data: organisationsDetails, isLoading: isOrganisationDetailsLoading } = useQuery({
    queryKey: [QUERY_STRING_ORGANISATION_DETAILS],
    queryFn: () => loadOrganisationDetails(organisationsData!.organisations, organisations!),
    enabled: !!organisationsData && !!organisations,
  });

  return (
    <>
      <NavBar />
      <Box m={2}>
        <Box pb={2} sx={{ borderBottom: 1, borderColor: 'lightGrey' }}>
          <Typography variant="h5">Organisations Overview</Typography>
        </Box>
        <OrganisationList
          data={organisationsDetails ?? []}
          loading={isOrganisationDetailsLoading || isOrganisationDataLoading}
        />
      </Box>
    </>
  );
};
