import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { OrganisationMember } from '../../../../domain/Organisation';
import OrganisationUsersContext from '../../../../context/OrganisationUsersContext/OrganisationUsersContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';

interface Props {
  reportsRecipientUserId?: string;
  onReportsRecipientUserIdChange: (userId: string | undefined) => Promise<void>;
}

interface UserOption {
  user: OrganisationMember;
  deleted: boolean;
}

export function ReportsUserSelect({ reportsRecipientUserId, onReportsRecipientUserIdChange }: Props) {
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [options, setOptions] = useState<UserOption[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const { users: organisationUsers, loading } = useContext(OrganisationUsersContext);

  const dispatch = useDispatch();

  const byEmail = (a: UserOption, b: UserOption) => a.user.email.localeCompare(b.user.email);

  useEffect(() => {
    const newOptions = [...organisationUsers.map((membership) => ({ user: membership, deleted: false }))].sort(byEmail);
    setOptions(newOptions);

    if (!selectedUser || organisationUsers.includes(selectedUser.user)) {
      setSelectedUser(newOptions.find(({ user }) => user.id === reportsRecipientUserId) ?? null);
    } else {
      // Keep the selected user, but mark it has deleted
      const optionForSelectedUser = { ...selectedUser, deleted: true };
      setSelectedUser(optionForSelectedUser);
    }
  }, [organisationUsers]);

  async function onChange(newValue: UserOption | string | null) {
    const newlySelectedUser =
      typeof newValue === 'string' ? options.find(({ user }) => user.email === newValue) ?? null : newValue;

    // If the user just typed in a non existent email, ignore
    if (typeof newValue === 'string' && newlySelectedUser === null) {
      return;
    }

    try {
      setSaving(true);
      await onReportsRecipientUserIdChange(newlySelectedUser?.user.id);
      setSelectedUser(newlySelectedUser);
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to update the configuration', error));
    } finally {
      setSaving(false);
    }
  }

  return (
    <Autocomplete
      sx={{ width: 350 }}
      isOptionEqualToValue={(option, value) => option.user.id === value.user.id}
      getOptionLabel={(option) => (typeof option === 'object' ? option.user.email : option)}
      defaultValue={selectedUser}
      value={selectedUser}
      onChange={(_event, value) => onChange(value)}
      options={options}
      loading={loading}
      freeSolo
      forcePopupIcon
      renderInput={(params) => (
        <TextField
          {...params}
          label="Reports will be sent to"
          error={selectedUser?.deleted}
          helperText={
            (selectedUser && !selectedUser.deleted) || loading
              ? undefined
              : selectedUser?.deleted
              ? 'User deleted. Report e-mail will not be sent!'
              : 'Report e-mail will not be sent!'
          }
          FormHelperTextProps={{ sx: { color: 'warning.main' } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading || saving ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(renderProps, option) => (
        <Box component="li" sx={{ '& > :first-of-type': { mr: 2, flexShrink: 0 } }} {...renderProps}>
          {option.user.permissions.includes('admin') ? (
            <Tooltip title="Admin">
              <ManageAccountsIcon />
            </Tooltip>
          ) : (
            <Icon />
          )}
          {option.user.email}
        </Box>
      )}
    />
  );
}
