import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import useNotifications from '../hooks/useNotifications/useNotifications';
import { currentTheme } from '../util/theme';

type Props = {
  children: ReactNode;
};

export default function App({ children }: Props) {
  useNotifications();

  return (
    <ThemeProvider theme={currentTheme}>
      <Box
        sx={(theme) => ({
          height: '100%',
          paddingTop: `${theme.customSizes.navbarHeightInPx}px`,
        })}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
}
