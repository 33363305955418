import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import { Controller, useFormContext } from 'react-hook-form';
import { APPOINTMENT_BOOKING_LINK_MORE_INFO_URL } from '../../../../domain/Appointments';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  disabled: boolean;
  opening: boolean;
};

export const AppointmentBookingCheckbox: React.FC<Props> = ({ disabled, opening }) => {
  const { control } = useFormContext();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Controller
        name="appointmentsAddInvitationLink"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            disabled={disabled}
            control={
              opening ? (
                <Box
                  width="38px"
                  height="38px"
                  component="span"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress size="14px" />
                </Box>
              ) : (
                <Checkbox checked={value} size="small" disableRipple onChange={onChange} />
              )
            }
            label={
              <Typography
                component="span"
                sx={{
                  color: disabled ? 'text.disabled' : 'text.primary',
                }}
              >
                Add appointment booking link
              </Typography>
            }
          />
        )}
        defaultValue={false}
      />

      <Link
        variant="body2"
        target="_blank"
        underline="always"
        href={APPOINTMENT_BOOKING_LINK_MORE_INFO_URL}
        fontSize="1rem"
      >
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography>More information</Typography>
          <OpenInNewIcon sx={{ fontSize: '16px' }} />
        </Stack>
      </Link>
    </Stack>
  );
};
