import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { MessageAccordion } from '../MessageAccordion/MessageAccordion';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { SMS_ONLY, EMAIL_ONLY, EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL } from '../../../../domain/ScheduledMessaging';
import { MessagingCodeSelect } from '../MessagingCodeSelect/MessagingCodeSelect';
import { MessageRecordingOptions } from '../MessageRecordingOptions/MessageRecordingOptions';
import { SendPreferenceOptions } from '../SendPreferenceOptions/SendPreferenceOptions';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { MaxPatientsSlider } from '../MaxPatientsSlider/MaxPatientsSlider';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { AppointmentBooking } from '../AppointmentBooking/AppointmentBooking';
import { Schedule } from '../Schedule/Schedule';
import { MinDaysBetweenMessagingRoundsSpinner } from '../MinDaysBetweenMessagingRoundsSpinner/MinDaysBetweenMessagingRoundsSpinner';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { MaxMessagingRoundsSpinner } from '../MaxMessagingRoundsSpinner/MaxMessagingRoundsSpinner';

export function RuleConfigurationTab() {
  const { getRequiredSelectedRule, updateRuleConfig, toggleUnsavedChanges } = useContext(rulesContext);

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const selectedRule = getRequiredSelectedRule();

  const { preference } = selectedRule.config;
  const emailAndOrSms = new Set([EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL]);

  const form = useForm<FieldValues>({
    defaultValues: {
      subject: selectedRule.config.emailTemplate?.subject ?? '',
      content: selectedRule.config.emailTemplate?.content ?? '',
      message: selectedRule.config.smsTemplate?.message ?? '',
      sendEmails: preference && preference !== SMS_ONLY,
      sendTexts: preference && preference !== EMAIL_ONLY,
      smsAndEmailPreference: preference ? (emailAndOrSms.has(preference) ? preference : EMAIL_AND_SMS) : undefined,
      messageRecordingChoice: selectedRule.config.messageRecordingChoice,
      extraCodes: selectedRule.config.extraCodes,
      appointmentsAddInvitationLink: selectedRule.config.appointments.addInvitationLink,
      appointmentsBookableWithinDays: selectedRule.config.appointments.selfBooking?.bookableWithinDays ?? 7,
      appointmentsSlotTypes: selectedRule.config.appointments.selfBooking?.slotTypes,
      appointmentsDescription: selectedRule.config.appointments.description,
      maxPatients: [
        selectedRule.config.patientSelectionCriteria.maxDefinedByPractice,
        selectedRule.config.patientSelectionCriteria.maxAllowedByAbtrace,
      ],
      minDaysBetweenMessagingRounds: selectedRule.config.patientSelectionCriteria.minDaysBetweenMessagingRounds,
      maxMessagingRounds: selectedRule.config.patientSelectionCriteria.maxMessagingRounds,
      scheduleDaysOfWeek: selectedRule.config.schedule.daysOfWeek,
      scheduleHour: selectedRule.config.schedule.hour,
    },
  });

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    toggleUnsavedChanges(form.formState.isDirty);
  }, [form.formState.isDirty]);

  async function doSave(
    {
      subject,
      content,
      message,
      sendEmails,
      sendTexts,
      smsAndEmailPreference,
      messageRecordingChoice,
      extraCodes,
      appointmentsAddInvitationLink,
      appointmentsBookableWithinDays,
      appointmentsSlotTypes,
      appointmentsDescription,
      maxPatients,
      minDaysBetweenMessagingRounds,
      maxMessagingRounds,
      scheduleDaysOfWeek,
      scheduleHour,
    }: FieldValues,
    enabled: boolean = selectedRule.config.enabled,
  ) {
    setUpdating(true);

    try {
      await updateRuleConfig(selectedRule.id, {
        enabled,
        patientSelectionCriteria: {
          maxDefinedByPractice: Math.min(...maxPatients),
          maxAllowedByAbtrace: maxPatients[1],
          minDaysBetweenMessagingRounds: parseInt(minDaysBetweenMessagingRounds),
          maxMessagingRounds: parseInt(maxMessagingRounds),
        },
        emailTemplate: {
          subject,
          content,
        },
        smsTemplate: {
          message,
        },
        preference:
          !sendEmails && !sendTexts
            ? undefined
            : sendEmails && !sendTexts
              ? EMAIL_ONLY
              : !sendEmails && sendTexts
                ? SMS_ONLY
                : smsAndEmailPreference,
        messageRecordingChoice,
        extraCodes,
        appointments: {
          addInvitationLink: appointmentsAddInvitationLink,
          description: appointmentsDescription,
          selfBooking:
            appointmentsAddInvitationLink && !!appointmentsBookableWithinDays && !!appointmentsSlotTypes
              ? {
                  bookableWithinDays: parseInt(appointmentsBookableWithinDays),
                  slotTypes: appointmentsSlotTypes,
                }
              : undefined,
        },
        schedule: {
          daysOfWeek: scheduleDaysOfWeek,
          hour: scheduleHour,
        },
      });

      form.reset({
        subject,
        content,
        message,
        sendEmails,
        sendTexts,
        smsAndEmailPreference,
        messageRecordingChoice,
        extraCodes,
        appointmentsAddInvitationLink,
        appointmentsBookableWithinDays,
        appointmentsSlotTypes,
        appointmentsDescription,
        maxPatients,
        minDaysBetweenMessagingRounds,
        maxMessagingRounds,
        scheduleDaysOfWeek,
        scheduleHour,
      });

      dispatch(enqueueSuccessNotification('Changes applied'));
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to save changes', error));
    } finally {
      setUpdating(false);
    }
  }

  function onSave(formValues: FieldValues) {
    const enabled = selectedRule.config.enabled;
    const { maxPatients, sendEmails, sendTexts } = formValues;
    const effectiveMaxPatients = Math.min(...maxPatients);
    if (effectiveMaxPatients === 0 && enabled) {
      return open({
        title: 'Save changes',
        text: 'The maximum number of patients to contact is set to 0. This will effectively disable the rule. Are you sure you want to continue?',
      }).then(async () => {
        return doSave(formValues, false);
      });
    } else if (!sendEmails && !sendTexts && enabled) {
      return open({
        title: 'Save changes',
        text: 'You have unchecked both E-Mails and SMS messages. This will effectively disable the rule. Are you sure you want to continue?',
      }).then(async () => {
        return doSave(formValues, false);
      });
    } else {
      return doSave(formValues);
    }
  }

  const sendEmails = form.getValues('sendEmails');
  const sendTexts = form.getValues('sendTexts');

  return (
    <FormProvider {...form}>
      <Stack gap={2}>
        <Stack overflow="auto" gap={2} height="100%" maxWidth={600}>
          <MessageAccordion />
          <SendPreferenceOptions visible={sendEmails && sendTexts} />
          <AppointmentBooking />
          <MessagingCodeSelect />
          <MessageRecordingOptions />
        </Stack>
        <MaxPatientsSlider />

        <FormControl component={Box}>
          <FormLabel>Messaging frequency</FormLabel>
          <Stack mt={2} ml={0} gap={1}>
            <Schedule />
            <MinDaysBetweenMessagingRoundsSpinner />
            <MaxMessagingRoundsSpinner />
          </Stack>
        </FormControl>

        <Stack direction="row" spacing={1} justifyContent="end" maxWidth={600}>
          <Button variant="outlined" onClick={() => form.reset()} disabled={!form.formState.isDirty || updating}>
            Reset
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(onSave)}
            loading={updating}
            disabled={updating || !form.formState.isDirty}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
      <ConfirmationDialog connector={connector} />
    </FormProvider>
  );
}
