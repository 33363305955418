import { HashRouter } from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from '../redux/store';
import App from './App';
import AmplifyProvider from './auth/AmplifyProvider/AmplifyProvider';
import Routes from './navigation/Routes/Routes';
import ConfigContext from '../context/ConfigContext';
import config from '../config/config';
import { NotificationsProvider } from './notifications/NotificationsProvider/NotificationsProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

type Props = {
  store: Store;
};

export function Root({ store }: Props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <Provider store={store}>
      <HashRouter>
        <ConfigContext.Provider value={config}>
          <AmplifyProvider>
            <QueryClientProvider client={queryClient}>
              <NotificationsProvider>
                <App>
                  <Routes />
                </App>
              </NotificationsProvider>
            </QueryClientProvider>
          </AmplifyProvider>
        </ConfigContext.Provider>
      </HashRouter>
    </Provider>
  );
}
