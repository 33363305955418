import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL } from '../../../../domain/ScheduledMessaging';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

interface Props {
  visible: boolean;
}

export function SendPreferenceOptions({ visible }: Props) {
  const { control } = useFormContext();

  return visible ? (
    <Controller
      name="smsAndEmailPreference"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <FormControl component={Box}>
              <FormLabel id="send-preference-options-label">E-Mail vs SMS</FormLabel>
              <RadioGroup
                aria-labelledby="send-preference-options-label"
                name="send-preference-options-radio-group"
                value={value}
                onChange={onChange}
                sx={{ marginLeft: 1.5 }}
              >
                <FormControlLabel
                  value={EMAIL_OR_SMS}
                  control={<Radio size="small" />}
                  label="E-Mail if available, otherwise SMS"
                />
                <FormControlLabel
                  value={SMS_OR_EMAIL}
                  control={<Radio size="small" />}
                  label="SMS if available, otherwise E-Mail"
                />
                <FormControlLabel
                  value={EMAIL_AND_SMS}
                  control={<Radio size="small" />}
                  label="Send both E-Mail and SMS"
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      }}
      defaultValue={EMAIL_AND_SMS}
    />
  ) : (
    <></>
  );
}
