import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserMembership } from '../../../domain/User';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';

interface Props {
  organisations: UserMembership[];
}

export default function UserOrganisations({ organisations }: Props) {
  const organisationIds = organisations.map((org) => org.name);
  const { organisations: details, loading } = useOrganisationDetails(organisationIds);

  return (
    <LoadingSection loading={loading}>
      {organisations.length === 0 ? (
        <>
          <Box ml={2} mt={2}>
            <Typography variant="body1">You do not belong to any organisation yet</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body1">You belong to the following organisations:</Typography>
          <List sx={{ maxWidth: 360 }}>
            {organisations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ name: id, permissions }) => (
                <ListItem key={id} component={Link} to={`/organisations/${id}`}>
                  <ListItemButton>
                    <ListItemText primary={organisationLabel(id, details ?? {})} secondary={permissions.join(', ')} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </>
      )}
    </LoadingSection>
  );
}
