import Box from '@mui/material/Box';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { useRef } from 'react';
import { DropdownOptionLabel } from './DropdownOptionLabel';
import { default as MuiPopper, PopperProps } from '@mui/material/Popper';
import { MULTIPLE_CHOICE_SELECT_TAG_LIMIT } from '../../../util/constants';
import CircularProgress from '@mui/material/CircularProgress';

export type Props<T extends object | string> = Omit<Partial<AutocompleteProps<T, true, false, false>>, 'onChange'> & {
  onChange: (selectedValue: T[]) => void;
  value: T[];
  options: T[];
  label: string;
  placeholder?: string;
  loading?: boolean;
  groupBy?: (option: T) => string;
  getPrimaryText: (item: T) => string;
  getSecondaryText?: (item: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  getInputParams?: (params: AutocompleteRenderInputParams) => AutocompleteRenderInputParams;
};

export const MultipleChoiceSelect = <T extends object | string>(props: Props<T>) => {
  const { label, placeholder, loading, onChange, getPrimaryText, getSecondaryText, getInputParams, ...rest } = props;

  const anchorRef = useRef<HTMLButtonElement>(null);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: T) => `${getPrimaryText(option)} ${getSecondaryText ? getSecondaryText(option) : ''}`,
  });

  const Popper = (popperProps: PopperProps) => {
    return <MuiPopper {...popperProps} anchorEl={anchorRef.current} />;
  };

  return (
    <Box>
      <Autocomplete
        size="small"
        ref={anchorRef}
        PopperComponent={Popper}
        {...rest}
        multiple
        disableCloseOnSelect
        limitTags={MULTIPLE_CHOICE_SELECT_TAG_LIMIT}
        onChange={(_e, v: T[]) => {
          onChange(v);
        }}
        getOptionLabel={getPrimaryText}
        renderOption={(renderProps, option, { selected }) => (
          <li {...renderProps}>
            <Checkbox size="small" checked={selected} />
            <DropdownOptionLabel
              primaryText={getPrimaryText(option)}
              secondaryText={getSecondaryText ? getSecondaryText(option) : undefined}
            />
          </li>
        )}
        filterOptions={filterOptions}
        renderInput={(params) => {
          const inputParams = getInputParams ? getInputParams(params) : params;
          const inputParamsWithLoadingAdornment = loading
            ? {
                ...inputParams,
                InputProps: {
                  ...inputParams.InputProps,
                  endAdornment: (
                    <>
                      <React.Fragment>
                        <CircularProgress color="inherit" size={20} />
                      </React.Fragment>
                      {inputParams.InputProps.endAdornment}
                    </>
                  ),
                },
              }
            : inputParams;
          return (
            <TextField
              {...inputParamsWithLoadingAdornment}
              variant="outlined"
              label={label}
              placeholder={placeholder}
            />
          );
        }}
      />
    </Box>
  );
};
