import { AxiosError } from 'axios';
import { ApiError } from '../../services/ApiError';

export function friendlierRegistrationError(message: string): string {
  let result;
  if (message.match(/PreSignUp failed with error .* is not allowed to register with ProactiveMonitoring/)) {
    return 'Only NHS users can register with ProactiveMonitoring';
  } else if (
    message.match(/Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern/)
  ) {
    return 'Password must contain at least 8 characters';
  } else if ((result = message.match(/Password did not conform with policy: (?<policy>.*)/))) {
    return result.groups.policy;
  } else {
    return message;
  }
}

export function friendlierAccountVerificationError(error: ApiError): string {
  const message = ((error.cause as AxiosError).response?.data as { error: string }).error;

  switch (message) {
    case 'Invalid code provided, please request a code again.':
      return 'Verification details expired or not found, please request a new link.';
    case 'Attempt limit exceeded, please try after some time.':
      return 'Attempt limit exceeded, please try again after some time.';
    case 'Invalid verification code provided, please try again.':
      return 'Invalid verification details, please try again or request a new link.';
    default:
      return message;
  }
}
