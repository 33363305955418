import { WHITELISTED_DOMAINS, WHITELISTED_MAIN_DOMAINS } from './constants';

export const extractEmailAddress = (input: string): string | null => {
  if (input.split('@').length !== 2) {
    return null;
  }

  // The line below removes all special characters that are NOT allowed in an email address
  const splittedInput = input.split(/[^'a-zA-Z0-9~!$@%^&*_\-=+}{.]/);
  const email = splittedInput.find((s) => s.includes('@'));
  return email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    ? email
    : null;
};

export const getMainDomainName = (subdomainName: string): string | null => {
  const array = subdomainName.split('.');
  const arrayLength = array.length;
  if (arrayLength <= 2) {
    return null;
  }
  return [array[arrayLength - 2], array[arrayLength - 1]].join('.');
};

export const getEmailDomainName = (email: string): string => {
  const array = email.split('@');
  if (array.length !== 2) {
    throw new Error(`Invalid email address: ${email}`);
  }
  return array[1];
};

export const shouldEmailBeAccepted = (email: string) => {
  const domainName = getEmailDomainName(email);
  if (WHITELISTED_DOMAINS.includes(domainName)) {
    return true;
  }
  const mainDomainName = getMainDomainName(domainName);

  if (mainDomainName && WHITELISTED_MAIN_DOMAINS.includes(mainDomainName)) {
    return true;
  }
  return false;
};
