import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleChoiceSelect } from '../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';
import { useFrontendDefinitions } from '../../../../hooks/useFrontendDefinitions/useFrontendDefinitions';

export function MessagingCodeSelect() {
  const { frontendDefinitions, loading, error } = useFrontendDefinitions();
  const messagingCodes = frontendDefinitions?.data.messaging_codes;

  const { control } = useFormContext();

  const sortedOptions = Object.entries(messagingCodes ?? {})
    .sort(([, labelA], [, labelB]) => labelA.localeCompare(labelB))
    .map(([code]) => code);

  return (
    <Box display="flex">
      <Controller
        name="extraCodes"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <FormControl sx={{ flex: 'auto' }}>
                <MultipleChoiceSelect
                  loading={loading}
                  options={sortedOptions}
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  getPrimaryText={(option) => messagingCodes?.[option] ?? ''}
                  label="Add codes to the patient record"
                  slotProps={{
                    paper: {
                      sx: {
                        border: '1px solid',
                        boxShadow: 1,
                      },
                    },
                  }}
                  getInputParams={(params) => ({
                    ...params,
                    ...(error
                      ? {
                          helperText: 'Unable to load messaging codes',
                          FormHelperTextProps: { sx: { color: 'warning.main' } },
                        }
                      : {}),
                  })}
                />
              </FormControl>
            </>
          );
        }}
        defaultValue={[]}
      />
    </Box>
  );
}
