import { extractEmailAddress, shouldEmailBeAccepted } from '../../../util/helpers';

export const extractEmailAddresses = (input: string): { accepted: string[]; rejected: string[] } => {
  const accepted: string[] = [];
  const rejected: string[] = [];

  const splittedInput = input.split(';');

  splittedInput.forEach((i) => {
    const email = extractEmailAddress(i);
    if (email && !accepted.includes(email) && !rejected.includes(email)) {
      if (shouldEmailBeAccepted(email)) {
        accepted.push(email);
      } else {
        rejected.push(email);
      }
    }
  });

  return { accepted, rejected };
};
