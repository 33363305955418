import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import NavBar from '../../components/navigation/NavBar/NavBar';
import { ContractExpiryDate } from '../../components/organisations/ContractExpiryDate';
import { EhrSelector } from '../../components/organisations/EhrSelector';
import { Messaging } from '../../components/organisations/Messaging/Messaging';
import { OptimisationConfig } from '../../components/organisations/OptimisationConfig/OptimisationConfig';
import OrganisationApplications from '../../components/organisations/OrganisationApplications/OrganisationApplications';
import OrganisationInvitations from '../../components/organisations/OrganisationInvitations/OrganisationInvitations';
import { OrganisationName } from '../../components/organisations/OrganisationName/OrganisationName';
import OrganisationUsers from '../../components/organisations/OrganisationUsers/OrganisationUsers';
import { ScheduledMessagingConfig } from '../../components/organisations/ScheduledMessagingConfig/ScheduledMessagingConfig';
import OrganisationUsersContext, {
  OrganisationUsersContextProvider,
} from '../../context/OrganisationUsersContext/OrganisationUsersContext';
import userContext from '../../context/UserContext';
import { isOrgAdmin } from '../../domain/User';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { PatientFacingServices } from '../../components/organisations/PatientFacingServices/PatientFacingServices';

const drawerWidth = 250;
export const scrollTopOffset = 10;

export default function OrganisationPage() {
  const {
    params: { name },
  } = useMatch('/organisations/:name')!;
  const organisation = name!;

  const { user } = useContext(userContext);
  const currentTheme = useTheme();

  const [selectedElement, setSelectedElement] = useState<HTMLElement | null>(null);

  const isAdmin = isOrgAdmin(user!, organisation);
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canAdmin = isSuperUser || isSupportUser || isAdmin;
  const canEditConfig = isSuperUser || isSupportUser;
  const membersSectionTitle = isAdmin ? 'Members' : 'Admins';

  const ehrSectionRef = useRef<HTMLDivElement | null>(null);
  const contractExpirySectionRef = useRef<HTMLDivElement | null>(null);
  const membersSectionRef = useRef<HTMLDivElement | null>(null);
  const invitationsSectionRef = useRef<HTMLDivElement | null>(null);
  const applicationsSectionRef = useRef<HTMLDivElement | null>(null);
  const optimisationSectionRef = useRef<HTMLDivElement | null>(null);
  const patientFacingSectionRef = useRef<HTMLDivElement | null>(null);
  const messagingSectionRef = useRef<HTMLDivElement | null>(null);
  const scheduledMessagingSectionRef = useRef<HTMLDivElement | null>(null);

  const sections = [
    {
      ref: ehrSectionRef,
      title: 'EHR',
      isVisible: canEditConfig,
      component: <EhrSelector />,
    },
    {
      ref: contractExpirySectionRef,
      title: 'Contract Expiry Date',
      isVisible: true,
      component: <ContractExpiryDate />,
    },
    {
      ref: membersSectionRef,
      title: membersSectionTitle,
      isVisible: true,
      component: <OrganisationUsers organisation={organisation} />,
    },
    {
      ref: invitationsSectionRef,
      title: 'Pending Invitations',
      isVisible: canAdmin,
      component: <OrganisationInvitations organisation={organisation} />,
    },
    {
      ref: applicationsSectionRef,
      title: 'Applications',
      isVisible: canAdmin,
      component: (
        <OrganisationUsersContext.Consumer>
          {({ refresh }) => (
            // Refresh users when an application is approved
            <OrganisationApplications organisation={organisation} onApplicationApproved={refresh} />
          )}
        </OrganisationUsersContext.Consumer>
      ),
    },
    {
      ref: optimisationSectionRef,
      title: 'Optimisation configuration',
      isVisible: canAdmin,
      component: <OptimisationConfig organisation={organisation} enabled={canEditConfig} />,
    },
    {
      ref: patientFacingSectionRef,
      title: 'Patient Facing Services',
      isVisible: canAdmin,
      component: <PatientFacingServices organisation={organisation} enabled={isSuperUser || isAdmin} />,
    },
    {
      ref: messagingSectionRef,
      title: 'Messaging',
      isVisible: canAdmin,
      component: <Messaging organisation={organisation} />,
    },
    {
      ref: scheduledMessagingSectionRef,
      title: 'Scheduled messaging configuration',
      isVisible: canAdmin,
      component: <ScheduledMessagingConfig />,
    },
  ];

  useEffect(() => {
    // Set the initially selected element to the first section in the list when the page is loaded
    setSelectedElement(sections[0].ref.current);

    // Define the scroll handler to detect which section is currently in view
    const onScroll = () => {
      const currentScrollPosition = document.documentElement.scrollTop;

      // Iterate the sections in reverse and stop as soon as we find an element that is in view
      for (const element of sections.slice(0).reverse()) {
        if (element.ref.current && element.ref.current.offsetTop !== 0) {
          const currentElementPosition =
            element.ref.current.offsetTop - currentTheme.customSizes.navbarHeightInPx - scrollTopOffset;

          if (currentElementPosition <= currentScrollPosition) {
            setSelectedElement(element.ref.current);
            break;
          }

          // If no element was matched, it means we are at the top of the page
          setSelectedElement(sections[0].ref.current);
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const isElementSelected = (element: HTMLElement | null) => {
    if (!element) {
      return false;
    }

    return element === selectedElement;
  };

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      // This is not strictly necessary to do (since the scroll event sets it)
      // but it makes the sidebar update before the scroll, which is the expected
      // visual behaviour (assuming instant scroll!)
      setSelectedElement(ref.current);

      const newScrollPosition = ref.current.offsetTop - currentTheme.customSizes.navbarHeightInPx;
      window.scrollTo({
        top: newScrollPosition - scrollTopOffset, // Add a small offset at the top
        behavior: 'instant',
      });
    }
  };

  return (
    <>
      <NavBar />

      <Box>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            ['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box' },
          }}
          ModalProps={{
            style: {
              position: 'relative',
              top: 'unset',
              left: 'unset',
              right: 'unset',
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List
              disablePadding
              sx={(theme) => ({
                ['& .MuiTypography-root']: { fontWeight: 500, fontSize: 18, padding: '10px' },
                ['& .Mui-selected']: { borderRight: `2px solid ${theme.palette.primary.main}` },
              })}
            >
              {sections
                .filter((element) => element.isVisible)
                .map((element) => (
                  <ListItem key={`${element.title}-list-item`} disablePadding>
                    <ListItemButton
                      onClick={() => scrollToSection(element.ref)}
                      selected={isElementSelected(element.ref.current)}
                    >
                      <ListItemText primary={element.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginLeft: `${drawerWidth}px`,
            ['& .organisation-section']: { marginTop: '48px' },
          }}
        >
          <OrganisationName />
          <OrganisationUsersContextProvider organisation={organisation}>
            {sections
              .filter((element) => element.isVisible)
              .map((element) => (
                <Box ref={element.ref} m={2} key={`${element.title}-item`} className="organisation-section">
                  <Typography variant="h5">{element.title}</Typography>
                  {element.component}
                </Box>
              ))}
          </OrganisationUsersContextProvider>
        </Box>
      </Box>
    </>
  );
}
