import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { Application } from '../../../domain/Application';
import { OrganisationDetails } from '../../../domain/Organisation';
import { organisationLabel } from '../../../util/organisationLabel/organisationLabel';

interface Props {
  applications: Application[];
  organisations: Record<string, OrganisationDetails | null>;
  onWithdraw: (application: Application) => void;
}

function formatDate(date: string): string {
  return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date));
}

export default function PendingUserApplicationsList({ applications, organisations, onWithdraw }: Props) {
  return (
    <>
      <List>
        {[...applications]
          .sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
          .map((application) => (
            <ListItem key={application.organisation}>
              <Grid container spacing="8" alignItems={'center'}>
                <Grid item xs>
                  <ListItemText
                    primary={organisationLabel(application.organisation, organisations)}
                    secondary={
                      <>
                        <div>{`Created at: ${formatDate(application.createdAt)}`}</div>
                        <div>{application.updatedAt && `Updated at: ${formatDate(application.updatedAt)}`}</div>
                      </>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Button onClick={() => onWithdraw(application)}>Withdraw</Button>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </>
  );
}
